import React from "react";
import { filterDeletedAppables, filterDeletedDonations, filterDeletedLeads, filterDeletedPolicies, getShowDeleted, } from "../../../../utils/utils";
import MemberNav from "./MemberNav";
export var MemberNavWrapper = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var member = _a.member, all_documents = _a.all_documents, donation_count = _a.donation_count, activity_log = _a.activity_log, _l = _a.showDeleted, showDeleted = _l === void 0 ? getShowDeleted() : _l;
    return (React.createElement(MemberNav, { member: member, order_count: (_b = member === null || member === void 0 ? void 0 : member.orders) === null || _b === void 0 ? void 0 : _b.length, lead_count: (_d = (_c = member === null || member === void 0 ? void 0 : member.leads) === null || _c === void 0 ? void 0 : _c.filter(function (el) {
            return filterDeletedLeads(el, showDeleted);
        })) === null || _d === void 0 ? void 0 : _d.length, app_count: (_f = (_e = member === null || member === void 0 ? void 0 : member.apps) === null || _e === void 0 ? void 0 : _e.filter(function (el) {
            return filterDeletedAppables(el, showDeleted);
        })) === null || _f === void 0 ? void 0 : _f.length, policy_count: (_h = (_g = member === null || member === void 0 ? void 0 : member.policies) === null || _g === void 0 ? void 0 : _g.filter(function (el) {
            return filterDeletedPolicies(el, showDeleted);
        })) === null || _h === void 0 ? void 0 : _h.length, document_count: all_documents.length, 
        // donation_count={member?.donations?.length}
        donation_count: (_k = (_j = member === null || member === void 0 ? void 0 : member.donations) === null || _j === void 0 ? void 0 : _j.filter(function (el) {
            return filterDeletedDonations(el, showDeleted);
        })) === null || _k === void 0 ? void 0 : _k.length, activity_log_count: activity_log === null || activity_log === void 0 ? void 0 : activity_log.length }));
};
