import React from "react";
import { Avatar, Tooltip } from "@mui/material";
var MemberStatusIcon = function (_a) {
    var status = _a.status;
    return (React.createElement(React.Fragment, null, status == "unmarketable" ? (React.createElement(Tooltip, { title: "Member Status: Unmarketable" },
        React.createElement(Avatar, { sx: { bgcolor: "#14943f" } }, "U"))) : status == "active" ? (React.createElement(Tooltip, { title: "Member Status: Active" },
        React.createElement(Avatar, { sx: { bgcolor: "#CCBB1E" } }, "A"))) : status == "pending" ? (React.createElement(Tooltip, { title: "Member Status: Pending" },
        React.createElement(Avatar, { sx: { bgcolor: "#aec2ec" } }, "P"))) : status == "deleted" ? (React.createElement(Tooltip, { title: "Member Status: Deleted" },
        React.createElement(Avatar, { sx: { bgcolor: "#cf1616" } }, "D"))) : status == "prospect" ? (React.createElement(Tooltip, { title: "Member Status: Prospect" },
        React.createElement(Avatar, { sx: { bgcolor: "#af1ecc" } }, "P"))) : (React.createElement(React.Fragment, null))));
};
export default MemberStatusIcon;
