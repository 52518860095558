import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import BrokerDetail from "./components/BrokerDetail";
import BrokerNav from "./components/BrokerNav";
import ModalOnboardingStatus from "../../components/Modals/ModalOnboardingStatus";
import ModalNotification from "../../components/Modals/ModalNotification";
import { Typography } from "@mui/material";
import AddTaskButton from "../Task/AddTaskButton";
var AbsMain = function (_a) {
    var _b;
    var response_data = _a.response_data, auth = _a.auth, flash = _a.flash;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState([]), onboardingStatuses = _d[0], setOnboardingStatuses = _d[1];
    var broker = response_data.broker, user = response_data.user;
    return (React.createElement(Layout, { title: "Broker Details", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen }, user.userable_type == "App\\Models\\AbsBroker" ? (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { container: true, xs: 12, alignItems: "center", justifyContent: "center" },
            React.createElement(BrokerNav, { user: user })),
        React.createElement(Grid, { container: true, justifyContent: "flex-end", xs: 12, mx: 1, my: 1 },
            React.createElement(Grid, null,
                React.createElement(ModalOnboardingStatus, { onboardingStatuses: onboardingStatuses, setOnboardingStatuses: setOnboardingStatuses, broker_id: broker.id, current_onboarding_status: (_b = broker.onboarding_status) !== null && _b !== void 0 ? _b : "" })),
            React.createElement(Grid, null,
                React.createElement(ModalNotification, { notification_args: [user.id], notification_method: "send_abs_onboarding_email", notification_modal_text: "Send Onboarding Email", notification_button_text: "Send Onboarding Email" })),
            React.createElement(Grid, null,
                React.createElement(ModalNotification, { notification_args: [user.id], notification_method: "send_abs_welcome_email", notification_modal_text: "Send Welcome Letter", notification_button_text: "Send Welcome Letter", button_color: "#F48024" })),
            React.createElement(Grid, null,
                React.createElement(AddTaskButton, { taskable_type: "AbsBroker", taskable_id: broker.id, variant: "contained", color: "success", size: "small" }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(BrokerDetail, { broker: broker, user: user })))) : (React.createElement(Grid, { container: true, xs: 12, alignItems: "center", justifyContent: "center" },
        React.createElement(Typography, { variant: "h6" }, "The user you are trying to view is not a broker.")))));
};
export default AbsMain;
