import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { router, usePage, Link } from "@inertiajs/react";
import route from "ziggy-js";
import { isEmpty, startCase } from "lodash";
import { Grid, Typography, Paper, Box, Button, TableContainer, Modal, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField, } from "@mui/material";
import { Alert } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Layout from "../Layout";
import UtmBuilderNav from "./components/UtmBuilderNav";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import DisplayErrors from "../../components/DisplayErrors";
var UtmBuilderAdmin = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var useStyles = makeStyles({
        headerRow: {
            backgroundColor: "rgba(0, 0, 0, 0.10)",
            color: "#000088",
            fontWeight: "bold",
            fontSize: "1.2em",
        },
        headerRowNoLabel: {
            backgroundColor: "rgba(0, 0, 0, 0.10)",
            color: "#e8e8e8",
            fontWeight: "bold",
            fontSize: "1.2em",
        },
        dataRowActive: {
            color: "#000000",
        },
        dataRowInactive: {
            color: "#aaaaaa",
        },
    });
    var classes = useStyles();
    var columns = [
        {
            field: "name",
            headerName: "Name",
            editable: true,
            headerClassName: classes.headerRow,
            flex: 1.5,
        },
        {
            field: "code",
            headerName: "Code",
            editable: true,
            headerClassName: classes.headerRow,
            flex: 1.5,
        },
        {
            field: "status",
            headerName: "Status",
            editable: false,
            flex: 0.5,
            headerClassName: classes.headerRow,
            renderCell: function (rowValues) {
                return "".concat(rowValues.row.status === "deleted"
                    ? "Inactive"
                    : startCase(rowValues.row.status));
            },
        },
        {
            field: "id",
            headerName: "ID",
            editable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            headerClassName: classes.headerRow,
            flex: 1,
        },
        {
            field: "actions",
            headerName: "...",
            sortable: false,
            filterable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            headerClassName: classes.headerRowNoLabel,
            align: "right",
            headerAlign: "right",
            flex: 1,
            renderCell: function (rowValues) {
                var _a, _b, _c, _d;
                var cur_row_id = rowValues.row.id;
                var child_link = "";
                if (use_children) {
                    var child_link = "/utm-builder/admin/view/" +
                        child_slug +
                        "/" +
                        cur_row_id;
                }
                var cnt_campaign = (_b = (_a = model_data === null || model_data === void 0 ? void 0 : model_data.filter(function (src) { return src.id == rowValues.row.id; })[0]) === null || _a === void 0 ? void 0 : _a.utm_builder_campaigns) === null || _b === void 0 ? void 0 : _b.length;
                var cnt_medium = (_d = (_c = model_data === null || model_data === void 0 ? void 0 : model_data.filter(function (src) { return src.id == rowValues.row.id; })[0]) === null || _c === void 0 ? void 0 : _c.utm_builder_mediums) === null || _d === void 0 ? void 0 : _d.length;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 5 }, use_children && (React.createElement(Link, { href: child_link, as: "span" },
                            React.createElement(Button, { type: "button", color: "primary", variant: "contained", style: {
                                    textTransform: "none",
                                }, fullWidth: true }, child_label +
                                (!!cnt_campaign
                                    ? " (" + cnt_campaign + ")"
                                    : "") +
                                (!!cnt_medium
                                    ? " (" + cnt_medium + ")"
                                    : ""))))),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(Link, { href: "/utm-builder/admin/toggle-status/" +
                                    model_slug +
                                    "/" +
                                    rowValues.id, as: "span" },
                                React.createElement(Button, { type: "button", color: rowValues.row.status == "active"
                                        ? "primary"
                                        : "secondary", variant: "outlined", fullWidth: true, style: {
                                        textTransform: "none",
                                    } }, rowValues.row.status == "active"
                                    ? "Deactivate"
                                    : "Activate"))),
                        React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(Button, { type: "button", variant: "outlined", fullWidth: true, style: {
                                    textTransform: "none",
                                }, onClick: function () {
                                    setOpenDelete(true);
                                    setCurId(rowValues.row.id);
                                } }, "Delete")))));
            },
        },
    ];
    var pagesize = 50;
    var rows = [];
    var objectives = response_data["utm_builder_objectives"];
    var sources = response_data["utm_builder_sources"];
    var model_slug = response_data["model_slug"];
    var model_data = response_data["model_data"];
    var use_children = response_data["use_children"];
    var model_title = "UTM " + startCase(model_slug);
    var child_label = response_data["child_label"] || "";
    var child_slug = response_data["child_slug"] || "";
    var cur_source = response_data["cur_source"] || {};
    var cur_campaign = response_data["cur_campaign"] || {};
    var _k = useForm(), handleSubmit = _k.handleSubmit, formErrors = _k.formState.errors, control = _k.control, reset = _k.reset;
    var _l = useState(false), openAdd = _l[0], setOpenAdd = _l[1];
    var _m = useState(false), openDelete = _m[0], setOpenDelete = _m[1];
    var _o = useState(0), curId = _o[0], setCurId = _o[1];
    var _p = useState(false), openMessage = _p[0], setOpenMessage = _p[1];
    var _q = usePage().props, flash = _q.flash, errors = _q.errors;
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    // add
    var submitAdd = function (data) {
        setOpenAdd(false);
        router.post(route("utm-builder-admin-add", { model_slug: model_slug }), {
            name: data.name,
            cur_source: cur_source.id || null,
            cur_campaign: cur_campaign.id || null,
        });
        reset();
    };
    var handleAdd = function () {
        setOpenAdd(true);
    };
    var handleCloseAdd = function () {
        setOpenAdd(false);
        reset();
    };
    // edit
    var handleCellEditCommit = React.useCallback(function (params) {
        var id = params.id;
        var field = params.field;
        var value = params.value;
        router.post(route("utm-builder-admin-edit", { model_slug: model_slug }), {
            id: id,
            field: field,
            value: value,
        });
    }, []);
    // delete
    var submitDelete = function () {
        setOpenDelete(false);
        router.post(route("utm-builder-admin-delete", { model_slug: model_slug }), {
            id: curId,
        });
    };
    var handleCloseDelete = function () {
        setOpenDelete(false);
    };
    return (React.createElement(Layout, { title: "UTM Builder Manager - " + model_title, menuOpen: true, toggleMenuOpen: function () { } },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(UtmBuilderNav, { objective_count: objectives === null || objectives === void 0 ? void 0 : objectives.length, source_count: sources === null || sources === void 0 ? void 0 : sources.length }),
                !isEmpty(serverErrors) && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                openMessage && (React.createElement(Box, { mb: 1 },
                    React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                        React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message)))))),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: "h4", component: "h3", className: "section-title", align: "center" },
                    React.createElement("br", null),
                    "UTM Builder Manager"),
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title", align: "center" },
                    (model_slug == "source" ||
                        model_slug == "objective") && React.createElement(React.Fragment, null, model_title),
                    model_slug == "campaign" && (React.createElement(React.Fragment, null,
                        React.createElement(Link, { href: "/utm-builder/admin/view/source" }, "UTM Sources"),
                        "\u00A0> Source: (",
                        cur_source.name,
                        ")")),
                    model_slug == "medium" && (React.createElement(React.Fragment, null,
                        React.createElement(Link, { href: "/utm-builder/admin/view/source" }, "UTM Sources"),
                        "\u00A0>\u00A0",
                        React.createElement(Link, { href: "/utm-builder/admin/view/campaign/" +
                                cur_source.id },
                            "Source: ",
                            cur_source.name),
                        "\u00A0> Campaign: ",
                        cur_campaign.name))))),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Button, { variant: "contained", style: {
                            backgroundColor: "#ddd",
                            color: "#333",
                            fontSize: "16px",
                            fontWeight: "400",
                            textTransform: "none",
                        }, onClick: handleAdd },
                        "+ Add ",
                        model_title)),
                React.createElement(Modal, { open: openAdd, onClose: handleCloseAdd },
                    React.createElement(Dialog, { open: openAdd, onClose: handleCloseAdd },
                        React.createElement(DialogTitle, null,
                            "Add ",
                            model_title),
                        React.createElement(DialogContent, { style: { minWidth: "500px" } },
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(GenericControllerTextInput, { name: "name", error: "name" in formErrors, label: model_title, control: control, defaultValue: "" })))),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleCloseAdd, color: "primary", size: "small" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitAdd), color: "primary", size: "small" }, "Add")))),
                React.createElement(Modal, { open: openDelete, onClose: handleCloseDelete },
                    React.createElement(Dialog, { open: openDelete, onClose: handleCloseDelete },
                        React.createElement(DialogTitle, null,
                            "Delete ",
                            model_title),
                        React.createElement(DialogContent, { style: { minWidth: "500px" } },
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Are you Sure?"),
                                    ((_c = (_b = model_data.filter(function (src) { return src.id == curId; })[0]) === null || _b === void 0 ? void 0 : _b.utm_builder_campaigns) === null || _c === void 0 ? void 0 : _c.length) ? (React.createElement("div", null,
                                        React.createElement("strong", null,
                                            "This UTM Source contains",
                                            " ", (_e = (_d = model_data.filter(function (src) {
                                            return src.id == curId;
                                        })[0]) === null || _d === void 0 ? void 0 : _d.utm_builder_campaigns) === null || _e === void 0 ? void 0 :
                                            _e.length,
                                            " ",
                                            "UTM Campaign(s).",
                                            React.createElement("br", null),
                                            "Deleting this UTM Source will delete all descendants and cannot be undone."))) : (""),
                                    ((_g = (_f = model_data.filter(function (cmp) { return cmp.id == curId; })[0]) === null || _f === void 0 ? void 0 : _f.utm_builder_mediums) === null || _g === void 0 ? void 0 : _g.length) ? (React.createElement("div", null,
                                        React.createElement("strong", null,
                                            "This UTM Campaign contains",
                                            " ", (_j = (_h = model_data.filter(function (cmp) {
                                            return cmp.id == curId;
                                        })[0]) === null || _h === void 0 ? void 0 : _h.utm_builder_mediums) === null || _j === void 0 ? void 0 :
                                            _j.length,
                                            " ",
                                            "UTM Medium(s).",
                                            React.createElement("br", null),
                                            "Deleting this UTM Campaign will delete all descendants and cannot be undone."))) : (""),
                                    React.createElement(Controller, { control: control, name: "id", defaultValue: curId, render: function (_a) {
                                            var field = _a.field;
                                            return (React.createElement(TextField, { hidden: true, error: "id" in errors }));
                                        } })))),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleCloseDelete, color: "primary", size: "small" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitDelete), color: "primary", size: "small" }, "Delete")))),
                React.createElement(TableContainer, { component: Paper, elevation: 3 },
                    React.createElement("div", { style: { width: "100%" } },
                        React.createElement(DataGrid, { pagination: true, rows: model_data, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: pagesize, rowsPerPageOptions: [pagesize], autoHeight: true, onCellEditCommit: handleCellEditCommit, getRowClassName: function (params) {
                                if (params.row.status == "active") {
                                    return classes.dataRowActive;
                                }
                                else {
                                    return classes.dataRowInactive;
                                }
                            } })))))));
};
export default UtmBuilderAdmin;
