import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import MemberSpouseInfo from "../../components/MemberSpouseInfo";
import MemberInfoForm from "../../components/MemberInfoForm";
import Address from "../../components/Address";
import { mailingAddressOptions, residentialAddressOptions, } from "../../utils/utils";
import MemberOptions from "../Membership/MemberOptions";
import UtmSection from "../Membership/UtmSection";
var MemberFormFields = function (_a) {
    var member = _a.member, states = _a.states, utm_sources = _a.utm_sources, utm_campaigns = _a.utm_campaigns, utm_mediums = _a.utm_mediums, referrals = _a.referrals, produceMembers = _a.produceMembers, errors = _a.errors, watch = _a.watch, watchAddress = _a.watchAddress, watchCity = _a.watchCity, control = _a.control, formType = _a.formType;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(MemberInfoForm, { member: member, control: control, errors: errors, data: member, produceMembers: produceMembers }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(Address, { data: member, control: control, errors: errors, states: states, options: mailingAddressOptions, produceMembers: produceMembers }),
                React.createElement(Address, { data: member, control: control, errors: errors, states: states, options: residentialAddressOptions, showResidentialSame: watchAddress != "" && watchCity != "", produceMembers: produceMembers }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(MemberSpouseInfo, { member: member, control: control, errors: errors, data: member, produceMembers: produceMembers }))),
        formType != "edit" && (React.createElement(Grid, { xs: 12 },
            React.createElement(UtmSection, { utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, errors: errors, watch: watch, control: control, formType: formType }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(MemberOptions, { member: member, control: control })))));
};
export default MemberFormFields;
