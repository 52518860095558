import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { helperFunctions } from "../../../../Helpers";
import React from "react";
var AppInfoCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var data = _a.data, insuranceCarriers = _a.insuranceCarriers;
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "App Info:"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "App ID: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = data === null || data === void 0 ? void 0 : data.id) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Lead ID: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = (_c = data === null || data === void 0 ? void 0 : data.lead) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : ""))),
        data.income && (React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Income: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_e = data === null || data === void 0 ? void 0 : data.income) !== null && _e !== void 0 ? _e : "")))),
        data.occupation && (React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Occupation:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_f = data === null || data === void 0 ? void 0 : data.occupation) !== null && _f !== void 0 ? _f : "")))),
        data.class_rating && (React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Class Rating:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_g = data === null || data === void 0 ? void 0 : data.class_rating) !== null && _g !== void 0 ? _g : "")))),
        data.class_rating && (React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Carrier: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_h = insuranceCarriers === null || insuranceCarriers === void 0 ? void 0 : insuranceCarriers.filter(function (el) {
                    return (el === null || el === void 0 ? void 0 : el.id) == (data === null || data === void 0 ? void 0 : data.insurance_carrier_id);
                })[0]) === null || _h === void 0 ? void 0 : _h.name)))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Best Time to Call:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_j = data === null || data === void 0 ? void 0 : data.best_time_call) !== null && _j !== void 0 ? _j : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Transferred To:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_l = (_k = data === null || data === void 0 ? void 0 : data.user_transferred) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : ""))),
        (data === null || data === void 0 ? void 0 : data.insurance_face_amount) && (React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Face Amount:"))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_m = data === null || data === void 0 ? void 0 : data.insurance_face_amount) !== null && _m !== void 0 ? _m : "")))),
        ((_o = data === null || data === void 0 ? void 0 : data.extra_data_json) === null || _o === void 0 ? void 0 : _o.deposit) && (React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Deposit:"))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("strong", null, ((_p = data === null || data === void 0 ? void 0 : data.extra_data_json) === null || _p === void 0 ? void 0 : _p.deposit)
                        ? "$" +
                            parseInt((_q = data === null || data === void 0 ? void 0 : data.extra_data_json) === null || _q === void 0 ? void 0 : _q.deposit).toLocaleString("en-US")
                        : ""))))),
        (data === null || data === void 0 ? void 0 : data.plan_type) && (React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Plan Type:"))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_r = data === null || data === void 0 ? void 0 : data.plan_type) !== null && _r !== void 0 ? _r : "")))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Upated At: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_s = helperFunctions.getDateTime(data === null || data === void 0 ? void 0 : data.updated_at)) !== null && _s !== void 0 ? _s : "")))));
};
export default AppInfoCard;
