import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { spouseFormOptions } from "../utils/utils";
import GenericNameForm from "./GenericFormFields/GenericNameForm";
import GenericPhoneInput from "./GenericFormFields/GenericPhoneInput";
import GenericControllerTextInput from "./GenericFormFields/TextInput";
var MemberSpouseInfo = function (_a) {
    var _b, _c, _d, _e;
    var member = _a.member, control = _a.control, errors = _a.errors, _f = _a.data, data = _f === void 0 ? {} : _f, produceMembers = _a.produceMembers;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Spouse/Family Member Information")),
        React.createElement(GenericNameForm, { control: control, member: member, errors: errors, data: data, options: spouseFormOptions, onblur: produceMembers }),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, type: "email", defaultValue: (_b = member === null || member === void 0 ? void 0 : member.spouse_email) !== null && _b !== void 0 ? _b : "", name: "spouse_email", label: "Spouse/Family Member Email", error: "spouse_email" in errors, onblur: produceMembers })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, type: "date", defaultValue: (_c = member === null || member === void 0 ? void 0 : member.spouse_birth_date) !== null && _c !== void 0 ? _c : "", name: spouseFormOptions.birth.name, label: spouseFormOptions.birth.label, shrinkLabel: true, error: false }))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericPhoneInput, { defaultValue: (_d = member === null || member === void 0 ? void 0 : member.spouse_phone) !== null && _d !== void 0 ? _d : "", label: "Spouse/Family Member Phone", name: "spouse_phone", control: control, required: false, helperText: "Please provide full phone (###) ###-####.", onblur: produceMembers })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericPhoneInput, { defaultValue: (_e = member === null || member === void 0 ? void 0 : member.spouse_phone_alt) !== null && _e !== void 0 ? _e : "", label: "Spouse/Family Member Alt. Phone Number", name: "spouse_phone_alt", control: control, required: false, helperText: "Please provide full phone (###) ###-####.", onblur: produceMembers })))));
};
export default MemberSpouseInfo;
