import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import { UtmAutoComplete } from "../../components/UtmAutoComplete";
import { Typography } from "@mui/material";
var UtmSection = function (_a) {
    var utm_sources = _a.utm_sources, utm_campaigns = _a.utm_campaigns, utm_mediums = _a.utm_mediums, referrals = _a.referrals, watch = _a.watch, control = _a.control, formType = _a.formType, errors = _a.errors;
    var watchUtmSource = watch("utm_source", "");
    var watchInternalSource = watch("internal_source", "");
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        formType == "add" && (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "UTMs")))),
        !watchInternalSource && (React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(UtmAutoComplete, { limitTags: 1, name: "utm_source", control: control, label: "Referral Source", options: utm_sources, rules: {
                    required: true,
                }, error: "utm_source" in errors, value: null, multiple: false }))),
        !watchUtmSource && (React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(UtmAutoComplete, { limitTags: 1, name: "internal_source", control: control, label: "Internal Source", options: referrals, rules: {
                    required: true,
                }, error: "internal_source" in errors, value: null, multiple: false }))),
        utm_mediums
            .map(function (el) { return el.member_utm_source_id; })
            .includes(watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id) ? (React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(UtmAutoComplete, { limitTags: 1, name: "utm_medium", control: control, utmSourceID: watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id, label: "Referral Campaign", options: utm_mediums.filter(function (el) {
                    return el.member_utm_source_id == (watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id);
                }), rules: {
                    required: true,
                }, error: "utm_medium" in errors, value: null, multiple: false }))) : (watchUtmSource && (React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(UtmAutoComplete, { limitTags: 1, name: "utm_campaign", control: control, utmSourceID: watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id, label: "Referral Campaign", options: utm_campaigns.filter(function (el) {
                    return el.member_utm_source_id ==
                        (watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id);
                }), rules: {
                    required: true,
                }, error: "utm_campaign" in errors, value: null, multiple: false }))))));
};
export default UtmSection;
