import { Box, Paper } from "@mui/material";
import React from "react";
import PolicyCard from "../Pages/Member/components/Policy/PolicyCard";
import PageTitle from "./PageTitle";
var PolicyTable = function (_a) {
    var data = _a.data, title = _a.title, states = _a.states, lead_types = _a.lead_types, insurance_types = _a.insurance_types, insurance_carriers = _a.insurance_carriers;
    if (!data.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: title })), data === null || data === void 0 ? void 0 :
        data.map(function (policy) { return (React.createElement(Box, { mb: 1, key: policy.id },
            React.createElement(Paper, null,
                React.createElement(PolicyCard, { member: policy.member, policy: policy, leadTypes: lead_types, states: states, insuranceTypes: insurance_types, insuranceCarriers: insurance_carriers })))); })));
};
export default PolicyTable;
