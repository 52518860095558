var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Typography } from "@mui/material";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
import Grid from "@mui/material/Unstable_Grid2";
var ReportDateSelect = function (_a) {
    var reportForm = _a.reportForm, errors = _a.errors, startControl = _a.startControl, endControl = _a.endControl, title = _a.title, startLabel = _a.startLabel, endLabel = _a.endLabel, startName = _a.startName, endName = _a.endName, _b = _a.required, required = _b === void 0 ? false : _b, options = __rest(_a, ["reportForm", "errors", "startControl", "endControl", "title", "startLabel", "endLabel", "startName", "endName", "required"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { component: "h4", variant: "h6" }, title)),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerTextInput, __assign({}, options, { control: startControl, label: startLabel, type: "date", name: startName, error: startName in errors, defaultValue: reportForm[startName], shrinkLabel: true }))),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerTextInput, __assign({}, options, { control: endControl, label: endLabel, type: "date", name: endName, defaultValue: reportForm[endName], error: endName in errors, shrinkLabel: true })))));
};
export default ReportDateSelect;
