import { Box, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import Layout from "../../../Layout";
import { MemberNavWrapper } from "../Navigation/MemberNavWrapper";
import MemberDocument from "./MemberDocument";
import FileUploadForm from "../../../../components/FileUploadForm";
import MemberHeader from "../MemberHeader";
var MemberDocuments = function (_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var member = response_data.member, all_documents = response_data.all_documents;
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(MemberNavWrapper, { member: member, all_documents: all_documents, activity_log: response_data === null || response_data === void 0 ? void 0 : response_data.activity_log }))),
        React.createElement(Paper, { style: { minHeight: "80vh" } },
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(MemberHeader, { member: member })),
                React.createElement(Grid, { item: true, xs: 9 }),
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(Box, { mx: 1, mt: 3 },
                        React.createElement(FileUploadForm, { memberId: member.id, userId: auth.user.id, name: "members", id: member.id }))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 2 },
                        React.createElement(MemberDocument, { data: all_documents })))))));
};
export default MemberDocuments;
