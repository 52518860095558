import { Box, Grid, Typography } from "@mui/material";
import { helperFunctions } from "../../../../Helpers";
import React from "react";
var LeadInfoCard = function (_a) {
    var _b, _c, _d, _e, _f;
    var lead = _a.lead;
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Lead Info:"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Lead ID: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = lead === null || lead === void 0 ? void 0 : lead.id) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Best Time to Call:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = lead === null || lead === void 0 ? void 0 : lead.best_time_call) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Transferred To:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_e = (_d = lead === null || lead === void 0 ? void 0 : lead.user_transferred) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Upated At: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_f = helperFunctions.getDateTime(lead === null || lead === void 0 ? void 0 : lead.updated_at)) !== null && _f !== void 0 ? _f : "")))));
};
export default LeadInfoCard;
