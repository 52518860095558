import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "../Layout";
import DisplayErrors from "../../components/DisplayErrors";
import AddMember from "../Member/AddMember";
import { hasPermission } from "../../utils/utils";
import { usePage } from "@inertiajs/react";
import OrderAddForm from "../Order/OrderAddForm";
import DonationAddForm from "../Donation/DonationAddForm";
import GenericRouteButton from "../../components/GenericRouteButton";
import FormMemberInfo from "../../components/FormMemberInfo";
var MembershipForm = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(), createdMember = _c[0], setCreatedMember = _c[1];
    var member = response_data.member, states = response_data.states, products = response_data.products, utm_sources = response_data.utm_sources, utm_campaigns = response_data.utm_campaigns, utm_mediums = response_data.utm_mediums, referrals = response_data.referrals, payment_options = response_data.payment_options;
    var auth = usePage().props.auth;
    return (React.createElement(Layout, { title: "Add Membership", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, direction: "column", xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12 }, !createdMember && (React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Add New Membership"))),
                !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                React.createElement(Grid, { xs: 12 }, createdMember ? (React.createElement(FormMemberInfo, { member: createdMember, spouseInfo: false, states: states })) : (React.createElement(AddMember, { errors: serverErrors, member: createdMember !== null && createdMember !== void 0 ? createdMember : member, states: states, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, setCreatedMember: setCreatedMember }))),
                hasPermission(auth, "add orders") && (createdMember === null || createdMember === void 0 ? void 0 : createdMember.id) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(OrderAddForm, { errors: serverErrors, member: createdMember !== null && createdMember !== void 0 ? createdMember : member, products: products, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, setCreatedMember: setCreatedMember, onMembershipForm: true }))),
                hasPermission(auth, "add donations") &&
                    (createdMember === null || createdMember === void 0 ? void 0 : createdMember.id) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DonationAddForm, { setCreatedMember: setCreatedMember, member: createdMember !== null && createdMember !== void 0 ? createdMember : member, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, errors: serverErrors, referrals: referrals, payment_options: payment_options, onMembershipForm: true }))),
                (createdMember === null || createdMember === void 0 ? void 0 : createdMember.id) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", spacing: 2, xs: 12, mt: 2 }, hasPermission(auth, "edit leads") &&
                        hasPermission(auth, "view leads") && (React.createElement(GenericRouteButton, { route_name: "lead_form_with_source", data: {
                            member_id: createdMember === null || createdMember === void 0 ? void 0 : createdMember.id,
                            source: "member_form",
                        }, buttonText: "Add Lead", backgroundColor: "#33B496", variant: "contained", buttonSize: "medium", fontSize: "14px" }))),
                    React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", spacing: 2, xs: 12, mt: 2 },
                        React.createElement(GenericRouteButton, { route_name: "show_member", data: createdMember === null || createdMember === void 0 ? void 0 : createdMember.id, buttonText: "Go To Member Profile", backgroundColor: "#1E5496", variant: "contained", buttonSize: "medium", fontSize: "14px" }))))))));
};
export default MembershipForm;
