import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
import GenericNumberInput from "../../../components/GenericFormFields/GenericNumberInput";
var Beneficiary = function (props) {
    var _a, _b;
    var errors = props.errors, app = props.app, options = props.options, control = props.control;
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericControllerTextInput, { label: options.name.label, rules: { required: false }, control: control, error: options.name.name in errors, name: options.name.name, defaultValue: app === null || app === void 0 ? void 0 : app[options.name.name] })),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericNumberInput, { control: control, label: options.percent.label, rules: {
                    required: false,
                    validate: function (value) {
                        if (!value) {
                            return true;
                        }
                        return value > 0;
                    },
                }, error: options.percent.name in errors, name: options.percent.name, defaultValue: (_a = app === null || app === void 0 ? void 0 : app[options.percent.name]) !== null && _a !== void 0 ? _a : null })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerTextInput, { label: options.birth_date.label, type: "date", control: control, rules: { required: false }, error: options.birth_date.name in errors, name: options.birth_date.name, defaultValue: (_b = app === null || app === void 0 ? void 0 : app[options.birth_date.name]) !== null && _b !== void 0 ? _b : "", shrinkLabel: true })),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericControllerTextInput, { label: options.relation.label, rules: { required: false }, control: control, error: options.relation.name in errors, name: options.relation.name, defaultValue: app === null || app === void 0 ? void 0 : app[options.relation.name] }))));
};
export default Beneficiary;
