var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { router } from "@inertiajs/react";
import route from "ziggy-js";
import { MagnifyingGlassIcon } from "./IconLibrary";
import GenericControllerTextInput from "./GenericFormFields/TextInput";
var MemberSearch = function () {
    var _a = useForm(), control = _a.control, handleSubmit = _a.handleSubmit, watch = _a.watch, errors = _a.formState.errors;
    var onSubmit = function (data) {
        if (!data.query && !data.zip) {
            return;
        }
        if (!data.query) {
            delete data.query;
        }
        if (!data.zip) {
            delete data.zip;
        }
        router.visit(route("member_search"), {
            method: "get",
            data: data,
            replace: false,
        });
    };
    return (React.createElement(SearchForm, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(GenericControllerTextInput, { className: "amac-input search-input", variant: "outlined", label: "Name | ID | Email | Phone", type: "text", control: control, name: "query", size: "small", inputProps: {
                maxLength: 200,
            }, placeholder: "Name | ID | Email | Phone", defaultValue: "" }),
        React.createElement(GenericControllerTextInput, { className: "amac-input search-zip", variant: "outlined", label: "Zip", type: "text", control: control, name: "zip", size: "small", placeholder: "Zip", inputProps: {
                maxLength: 5,
            }, defaultValue: "" }),
        React.createElement("div", { className: "searchBtn", onClick: handleSubmit(onSubmit) },
            React.createElement(MagnifyingGlassIcon, { color: "#3F3F3F" })),
        React.createElement("input", { type: "submit", style: { display: "none" } })));
};
var SearchForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n\n    .search-input {\n        width: 300px;\n    }\n\n    .search-zip {\n        width: 125px;\n    }\n\n    input {\n        padding-right: 35px;\n    }\n\n    .searchBtn {\n        display: inline-flex;\n        height: 40px;\n        width: 28px;\n        position: absolute;\n        right: 0;\n        border: 1px;\n        cursor: pointer;\n    }\n\n    .searchBtn:hover path {\n        fill: #3f51b5;\n    }\n\n    .searchBtn svg {\n        width: 18px;\n    }\n"], ["\n    position: relative;\n\n    .search-input {\n        width: 300px;\n    }\n\n    .search-zip {\n        width: 125px;\n    }\n\n    input {\n        padding-right: 35px;\n    }\n\n    .searchBtn {\n        display: inline-flex;\n        height: 40px;\n        width: 28px;\n        position: absolute;\n        right: 0;\n        border: 1px;\n        cursor: pointer;\n    }\n\n    .searchBtn:hover path {\n        fill: #3f51b5;\n    }\n\n    .searchBtn svg {\n        width: 18px;\n    }\n"])));
export default MemberSearch;
var templateObject_1;
