import React, { useState } from "react";
import { Button, Box, Modal, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, } from "@mui/material";
import { router } from "@inertiajs/react";
import { Controller, useForm } from "react-hook-form";
import GenericControllerDropdownInput from "../../../../components/GenericFormFields/DropdownInput";
var LeadSetSpouse = function (_a) {
    var _b;
    var data = _a.data, options = _a.options, url = _a.url, title = _a.title, label = _a.label, name = _a.name, id = _a.id, buttonName = _a.buttonName, _c = _a.backgroundColor, backgroundColor = _c === void 0 ? "#392869" : _c;
    var _d = useForm(), control = _d.control, handleSubmit = _d.handleSubmit;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        router.post(url, data);
    };
    return (React.createElement(Box, null,
        React.createElement(Button, { variant: "contained", style: {
                backgroundColor: backgroundColor,
                color: "#FFF",
            }, onClick: handleChange, fullWidth: true, size: "small" }, buttonName),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, title),
                React.createElement(DialogContent, null,
                    React.createElement(GenericControllerDropdownInput, { name: name, rules: {
                            required: true,
                        }, control: control, id: "".concat(name, "_id"), label: label, defaultValue: (_b = data[name]) !== null && _b !== void 0 ? _b : null, handleChange: handleChange, options: options
                            .sort(function (x, y) {
                            return x.id == 12 ? -1 : y.id == 12 ? 1 : 0;
                        })
                            .map(function (opt) {
                            return (React.createElement(MenuItem, { key: opt.id, value: opt.id }, opt.name));
                        }) }),
                    React.createElement(Controller, { control: control, name: "id", defaultValue: id, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } }),
                    React.createElement(Controller, { control: control, name: "route", defaultValue: window.location.href, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Change"))))));
};
export default LeadSetSpouse;
