import React from "react";
import { FormLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerCheckboxInput from "../../../components/GenericFormFields/CheckboxInput";
var BrokerQuestions = function (props) {
    var data = props.data, control = props.control, errors = props.errors;
    var interestedOptions = [
        { name: "diversifying", label: "Diversifying my current business" },
        { name: "growing_revenue", label: "Growing my revenue" },
        { name: "amac_values", label: "All that AMAC stands for" },
    ];
    var wantOptions = [
        { name: "want_leads", label: "Leads" },
        { name: "want_support", label: "Sales Support" },
        { name: "want_literature", label: "Sales Literature" },
        { name: "want_training", label: "'A' Rated Carriers" },
        { name: "want_carriers", label: "Training" },
    ];
    var offerOptions = [
        { name: "offer_med_c", label: "Medicare Advantage (Medicare Part C)" },
        { name: "offer_medigap", label: "Medicare Supplement Plans (MediGap)" },
        {
            name: "offer_med_d",
            label: "Medicare Prescription Drug Plans (Medicare Part D)",
        },
        { name: "offer_gal", label: "Guaranteed Acceptance Life" },
        { name: "offer_end_life", label: "Individual Life Insurance" },
        { name: "offer_group_life", label: "Group Life Insurance" },
        { name: "offer_group_health", label: "Group Health Insurance" },
        { name: "offer_ltc", label: "Long Term Care Insurance" },
        { name: "offer_dental", label: "Dental Insurance" },
        { name: "offer_vision", label: "Vision Insurance" },
    ];
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Broker Questions")),
        React.createElement(Grid, null,
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(FormLabel, { component: "legend" }, "I am interested in / feel strongly about:"),
                interestedOptions.map(function (el) {
                    var _a;
                    return (React.createElement(Grid, { xs: 12, md: 4, key: el.name },
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_a = data[el.name]) !== null && _a !== void 0 ? _a : false, name: "broker[".concat(el.name, "]"), error: errors.error.errors.broker &&
                                el.name in errors.error.errors.broker, control: control, label: el.label })));
                })),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(FormLabel, { component: "legend" }, "I want access to:"),
                wantOptions.map(function (el) {
                    var _a;
                    return (React.createElement(Grid, { xs: 12, md: 4, key: el.name },
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_a = data[el.name]) !== null && _a !== void 0 ? _a : false, control: control, label: el.label, name: "broker[".concat(el.name, "]"), error: errors.error.errors.broker &&
                                el.name in errors.error.errors.broker })));
                })),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(FormLabel, { component: "legend" }, "I want to be able to offer:"),
                offerOptions.map(function (el) {
                    var _a;
                    return (React.createElement(Grid, { xs: 12, md: 4, key: el.name },
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_a = data[el.name]) !== null && _a !== void 0 ? _a : false, control: control, label: el.label, name: "broker[".concat(el.name, "]"), error: errors.error.errors.broker &&
                                el.name in errors.error.errors.broker })));
                })))));
};
export default BrokerQuestions;
