import React, { useState } from "react";
import Layout from "../Layout";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { getShowDeleted } from "../../utils/utils";
import ModalActivity from "../../components/Modals/ModalActivity";
import ActivityForm from "./components/ActivityForm";
var MemberActivityLog = function (_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var user = response_data.user;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(function () { return getShowDeleted(); }), showDeleted = _c[0], setShowDeleted = _c[1];
    var max_rows = 100;
    var member = response_data.member, all_documents = response_data.all_documents, activity_log = response_data.activity_log, serverErrors = response_data.serverErrors;
    var data = activity_log.data;
    var links = activity_log.links;
    var num_rows = response_data["activity_log"].length;
    var rows = [];
    var _d = React.useState(100), pageSize = _d[0], setPageSize = _d[1];
    var max_display_size = 40;
    var hidden_keys = ["remember_token", "password"];
    data.forEach(function (item) {
        item.new_display = "";
        item.new_display_comma = "";
        if (item.new) {
            var obj_new = JSON.parse(item.new);
            Object.entries(obj_new).forEach(function (row_new) {
                var key = row_new[0], value = row_new[1];
                var value_display = JSON.stringify(value);
                if (value) {
                    if (hidden_keys.includes(key)) {
                        value_display = "[not shown]";
                    }
                    item.new_display =
                        item.new_display + key + ": " + value_display + "\n";
                    if (value_display.length > 0) {
                        if (value_display.length > max_display_size) {
                            item.new_display_comma =
                                item.new_display_comma +
                                    key +
                                    ": " +
                                    value_display.substring(0, max_display_size) +
                                    "..., ";
                        }
                        else {
                            item.new_display_comma =
                                item.new_display_comma +
                                    key +
                                    ": " +
                                    value_display +
                                    ", ";
                        }
                    }
                }
            });
        }
        item.old_display = "";
        item.old_display_comma = "";
        if (item.old) {
            var obj_old = JSON.parse(item.old);
            Object.entries(obj_old).forEach(function (row_old) {
                var key = row_old[0], value = row_old[1];
                var value_display = JSON.stringify(value);
                if (value) {
                    if (hidden_keys.includes(key)) {
                        value_display = "[not shown]";
                    }
                    item.old_display =
                        item.old_display + key + ": " + value_display + "\n";
                    if (value_display.length > 0) {
                        if (value_display.length > max_display_size) {
                            item.old_display_comma =
                                item.old_display_comma +
                                    key +
                                    ": " +
                                    value_display.substring(0, max_display_size) +
                                    "..., ";
                        }
                        else {
                            item.old_display_comma =
                                item.old_display_comma +
                                    key +
                                    ": " +
                                    value_display +
                                    ", ";
                        }
                    }
                }
            });
        }
    });
    return (React.createElement(Layout, { title: "Agent Activity Log", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            num_rows == max_rows && (React.createElement("div", null,
                React.createElement("em", null,
                    "Please note: A maximum of ",
                    num_rows,
                    " results are shown below, and some results may be missing."))),
            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 })),
        React.createElement(Grid, { container: true, m: 1, p: 1, xs: 12 },
            React.createElement("h3", null,
                user.first_name,
                " ",
                user.last_name,
                "'s Activity Log")),
        React.createElement(ActivityForm, { data: response_data, serverErrors: serverErrors }),
        React.createElement(Grid, { container: true, m: 1, p: 1, xs: 12 }, links.map(function (link, index) { return (React.createElement(Link, { href: link.url, key: index, underline: "hover", sx: link.active
                ? { fontWeight: "bold", mr: 1 }
                : { fontWeight: "normal", mr: 1 } },
            "\u00A0",
            link.label
                .replace("&laquo;", "<")
                .replace("&raquo;", ">"))); })),
        React.createElement(Grid, { container: true, m: 1, p: 1, xs: 12 },
            React.createElement(TableContainer, { component: Paper, elevation: 3 },
                React.createElement(Table, { sx: { minWidth: 650 }, "aria-label": "simple table" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null),
                            React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "ID"),
                            React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Log Name"),
                            React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Date"),
                            React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Description"),
                            React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Member"),
                            React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "New"),
                            React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Old"))),
                    React.createElement(TableBody, null, data.map(function (activity) { return (React.createElement(TableRow, { key: activity.id, sx: {
                            "&:last-child td, &:last-child th": {
                                border: 0,
                            },
                        } },
                        React.createElement(TableCell, { sx: { verticalAlign: "top" } },
                            React.createElement(ModalActivity, { activity: activity, current_transferred: user.id, created_by: activity.causer_id, lead_type_id: activity.id, model: "activity", model_id: activity.id })),
                        React.createElement(TableCell, { sx: { verticalAlign: "top" }, component: "th", scope: "row" }, activity.id),
                        React.createElement(TableCell, { sx: { verticalAlign: "top" } }, activity.log_name),
                        React.createElement(TableCell, { sx: { verticalAlign: "top" } }, new Date(activity.created_at).toLocaleString()),
                        React.createElement(TableCell, { sx: { verticalAlign: "top" } }, activity.description),
                        React.createElement(TableCell, { sx: { verticalAlign: "top" } }, activity.full_name),
                        React.createElement(TableCell, { sx: {
                                maxWidth: 300,
                                fontSize: 10,
                                whiteSpace: "pre-wrap",
                                verticalAlign: "top",
                            } }, activity.new_display_comma),
                        React.createElement(TableCell, { sx: {
                                maxWidth: 300,
                                fontSize: 10,
                                whiteSpace: "pre-wrap",
                                verticalAlign: "top",
                            } }, activity.old_display_comma))); })))))));
};
export default MemberActivityLog;
