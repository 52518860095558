import React from "react";
import { Typography, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import LifeIndividualQuestions from "./LifeIndividualQuestions";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
var LifeLead = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var lead = _a.lead, control = _a.control, errors = _a.errors;
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
        React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
            React.createElement(Grid, { container: true, xs: 6 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Height")),
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerTextInput, { label: "Feet", type: "number", rules: { required: false, max: 9, min: 0 }, control: control, name: "extra_data_json[height_feet]", defaultValue: (_c = (_b = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _b === void 0 ? void 0 : _b.height_feet) !== null && _c !== void 0 ? _c : "", error: errors.extra_data_json
                            ? "height_feet" in errors.extra_data_json
                            : false })),
                React.createElement(Grid, { container: true, xs: 1, alignItems: "center", justifyContent: "center" }, "-"),
                React.createElement(Grid, { xs: 5 },
                    React.createElement(GenericControllerTextInput, { label: "Inches", type: "number", rules: { required: false, max: 11, min: 0 }, control: control, name: "extra_data_json[height_inches]", defaultValue: (_e = (_d = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _d === void 0 ? void 0 : _d.height_inches) !== null && _e !== void 0 ? _e : "", error: errors.extra_data_json
                            ? "height_inches" in errors.extra_data_json
                            : false }))),
            React.createElement(Grid, { container: true, xs: 6 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Weight")),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(GenericControllerTextInput, { label: "lbs", type: "number", rules: { required: false, max: 999, min: 0 }, control: control, name: "extra_data_json[weight]", defaultValue: (_g = (_f = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _f === void 0 ? void 0 : _f.weight) !== null && _g !== void 0 ? _g : "", error: errors.extra_data_json
                            ? "weight" in errors.extra_data_json
                            : false, fullWidth: true }))),
            React.createElement(LifeIndividualQuestions, { errors: errors, control: control, lead: lead }))));
};
export default LifeLead;
