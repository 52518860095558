import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import GenericControllerTextInput from "./TextInput";
var GenericNameForm = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var control = props.control, member = props.member, errors = props.errors, options = props.options, onblur = props.onblur, _l = props.data, data = _l === void 0 ? {} : _l;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12, md: 1 },
            React.createElement(GenericControllerTextInput, { control: control, label: options.prefix.label, name: options.prefix[data.formType], rules: {
                    required: options.prefix.required,
                }, defaultValue: (_b = (_a = data === null || data === void 0 ? void 0 : data[options.prefix[data.formType]]) !== null && _a !== void 0 ? _a : member[options.prefix["name"]]) !== null && _b !== void 0 ? _b : "", error: options.prefix.name in errors, autoFocus: options.prefix.focus })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericControllerTextInput, { control: control, label: options.first_name.label, name: options.first_name[data.formType], rules: {
                    required: options.first_name.required,
                }, defaultValue: (_d = (_c = data === null || data === void 0 ? void 0 : data[options.first_name[data.formType]]) !== null && _c !== void 0 ? _c : member[options.first_name["name"]]) !== null && _d !== void 0 ? _d : "", error: options.first_name.name in errors, autoFocus: options.first_name.focus })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(GenericControllerTextInput, { control: control, label: options.middle_name.label, name: options.middle_name[data.formType], rules: { required: options.middle_name.required }, defaultValue: (_f = (_e = data === null || data === void 0 ? void 0 : data[options.middle_name[data.formType]]) !== null && _e !== void 0 ? _e : member[options.middle_name["name"]]) !== null && _f !== void 0 ? _f : "", error: options.middle_name.name in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericControllerTextInput, { onblur: onblur ? onblur : undefined, control: control, label: options.last_name.label, name: options.last_name[data.formType], rules: { required: options.last_name.required }, defaultValue: (_h = (_g = data === null || data === void 0 ? void 0 : data[options.last_name[data.formType]]) !== null && _g !== void 0 ? _g : member[options.last_name["name"]]) !== null && _h !== void 0 ? _h : "", error: options.last_name.name in errors })),
        React.createElement(Grid, { xs: 12, md: 1 },
            React.createElement(GenericControllerTextInput, { control: control, label: options.suffix.label, name: options.suffix[data.formType], rules: { required: options.suffix.required }, defaultValue: (_k = (_j = data === null || data === void 0 ? void 0 : data[options.suffix[data.formType]]) !== null && _j !== void 0 ? _j : member[options.suffix["name"]]) !== null && _k !== void 0 ? _k : "", error: options.suffix.name in errors }))));
};
export default GenericNameForm;
