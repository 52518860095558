import { Box, Grid, Paper, Snackbar, Typography, Link, } from "@mui/material";
import orderBy from "lodash/orderBy";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import MemberAction from "./components/Member/MemberAction";
import MemberInfoCard from "./components/Member/MemberInfoCard";
import MemberActivityCard from "./components/ActivityLog/MemberActivityCard";
import MemberContactCard from "./components/Member/MemberContactCard";
import MemberShipAddressCard from "./components/Member/MemberShipAddressCard";
import MemberResAddressCard from "./components/Member/MemberResAddressCard";
import MemberNoteCard from "./components/Member/MemberNoteCard";
import { Alert } from "@mui/material";
import { agentHasRole } from "../../utils/utils";
import { usePage } from "@inertiajs/react";
import { ErrorOutline } from "@mui/icons-material";
import { MemberNavWrapper } from "./components/Navigation/MemberNavWrapper";
import MemberHeader from "./components/MemberHeader";
var MemberMain = function (_a) {
    var response_data = _a.response_data, flash = _a.flash, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(false), openMessage = _c[0], setOpenMessage = _c[1];
    var auth = usePage().props.auth;
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    var member = response_data.member, member_statuses = response_data.member_statuses, member_leave_reasons = response_data.member_leave_reasons, states = response_data.states, all_documents = response_data.all_documents, activity_log = response_data.activity_log;
    member.orders = orderBy(member === null || member === void 0 ? void 0 : member.orders, ["created_at"], "desc");
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(MemberNavWrapper, { member: member, all_documents: all_documents, activity_log: activity_log }),
                openMessage && (React.createElement(Box, { mb: 1 },
                    React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                        React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message)))))),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Paper, { style: { minHeight: "80vh" } },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { container: true, item: true, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12, md: 3 },
                        React.createElement(MemberHeader, { member: member })),
                    !agentHasRole(auth, "broker") && (React.createElement(Grid, { item: true, xs: 12, md: 9 },
                        React.createElement(Box, { mx: 2, my: 2 },
                            React.createElement(MemberAction, { member: member, memberLeaveReasons: member_leave_reasons, memberStatuses: member_statuses }))))),
                member.merged_to_id && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(Alert, { variant: "filled", severity: "error", icon: React.createElement(ErrorOutline, { fontSize: "large" }) },
                            React.createElement(Typography, { variant: "h6" },
                                "This member was transferred to\u00A0",
                                React.createElement(Link, { href: "/member/show/" +
                                        member.merged_to_id, color: "inherit" },
                                    "#",
                                    member.merged_to_id)))))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(MemberInfoCard, { member: member }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(MemberActivityCard, { member: member }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(MemberNoteCard, { member: member }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(MemberContactCard, { member: member }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(MemberShipAddressCard, { member: member, states: states }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(MemberResAddressCard, { member: member, states: states })))))));
};
export default MemberMain;
