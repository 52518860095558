import React, { useState } from "react";
import Layout from "../Layout";
import MemberRecordings from "../Member/components/Member/MemberRecordings";
var Show = function (_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var recordings = response_data.recordings, member = response_data.member;
    return (React.createElement(Layout, { title: "Recordings", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(MemberRecordings, { recordings: recordings, member: member })));
};
export default Show;
