import { Button, Dialog, DialogActions, DialogTitle, Modal, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
var ModalFail = function (_a) {
    var status = _a.status, objectType = _a.objectType;
    var _b = useState(status == "Fail"), open = _b[0], setOpen = _b[1];
    useEffect(function () {
        if (status === "Fail") {
            setOpen(true);
        }
    }, [status]);
    var handleClose = function () {
        setOpen(false);
    };
    return status === "Pending" ? (React.createElement(React.Fragment, null)) : status === "Success" ? (React.createElement(Typography, { variant: "subtitle2", color: "success" },
        objectType,
        " succeeded!")) : (React.createElement(Modal, { open: open, onClose: handleClose },
        React.createElement(Dialog, { open: open, onClose: handleClose },
            React.createElement(DialogTitle, { id: "form-dialog-title" },
                objectType,
                " failed."),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel")))));
};
export default ModalFail;
