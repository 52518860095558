import { ListItemText, MenuItem, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import GenericNumberInput from "../../../components/GenericFormFields/GenericNumberInput";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
import GenericRadioInput from "../../../components/GenericFormFields/GenericRadioInput";
import GenericControllerDropdownInput from "../../../components/GenericFormFields/DropdownInput";
import NumberFormat from "react-number-format";
import GenericControllerSelect from "../../../components/GenericFormFields/Select";
var FinancialInfoSection = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var app = props.app, control = props.control, errors = props.errors, insurance_types = props.insurance_types, insurance_carriers = props.insurance_carriers;
    var _t = useState((_b = (_a = app === null || app === void 0 ? void 0 : app.extra_data_json) === null || _a === void 0 ? void 0 : _a.commission_value) !== null && _b !== void 0 ? _b : ""), commissionValue = _t[0], setCommissionValue = _t[1];
    var _u = useState(app.insurance_type_id), insuranceTypeId = _u[0], setInsuranceTypeId = _u[1];
    var sorted_carriers = insurance_carriers.sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
    });
    return (React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "App Information")),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerTextInput, { type: "date", label: "Effective Date", name: "effective_date", control: control, rules: { required: false }, defaultValue: (_c = app === null || app === void 0 ? void 0 : app.effective_date) !== null && _c !== void 0 ? _c : "", error: "effective_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericControllerDropdownInput, { name: "extra_json[insurance_carrier_id]", id: "insurance_carrier_id", label: "Insurance Carrier", rules: {
                    required: false,
                }, control: control, defaultValue: (_e = (_d = app === null || app === void 0 ? void 0 : app.extra_data_json) === null || _d === void 0 ? void 0 : _d.insurance_carrier_id) !== null && _e !== void 0 ? _e : "", error: "insurance_carrier_id" in errors, handleChange: function (e) { }, options: sorted_carriers.map(function (insurance) { return (React.createElement(MenuItem, { key: "insurance_carriers_".concat(insurance.id), value: insurance.id }, insurance.name)); }) })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericRadioInput, { defaultValue: (_g = (_f = app === null || app === void 0 ? void 0 : app.extra_data_json) === null || _f === void 0 ? void 0 : _f.underwritten) !== null && _g !== void 0 ? _g : "", control: control, rules: { required: false }, name: "extra_json[underwritten]", legend: "Policy underwritten", error: "underwritten" in errors })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericRadioInput, { defaultValue: (_j = (_h = app === null || app === void 0 ? void 0 : app.extra_data_json) === null || _h === void 0 ? void 0 : _h.docs) !== null && _j !== void 0 ? _j : "", control: control, rules: { required: false }, name: "extra_json[docs]", legend: "Docs", error: "docs" in errors })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericRadioInput, { defaultValue: (_l = (_k = app === null || app === void 0 ? void 0 : app.extra_data_json) === null || _k === void 0 ? void 0 : _k.rewrite) !== null && _l !== void 0 ? _l : "", control: control, rules: { required: false }, name: "extra_json[rewrite]", legend: "Rewrite", yesLabel: "Rewrite", noLabel: "New Business", yesValue: "rewrite", noValue: "new business", error: "rewrite" in errors })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerSelect, { control: control, label: "Money Type", name: "extra_json[money_type]", defaultValue: (_o = (_m = app === null || app === void 0 ? void 0 : app.extra_data_json) === null || _m === void 0 ? void 0 : _m.money_type) !== null && _o !== void 0 ? _o : "", customOptions: [
                    { id: "qualified", name: "Qualified" },
                    { id: "non-qualified", name: "Non-Qualified" },
                ].map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                    React.createElement(ListItemText, { primary: option.name }))); }) })),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericNumberInput, { label: "Deposit", defaultValue: (_q = (_p = app === null || app === void 0 ? void 0 : app.extra_data_json) === null || _p === void 0 ? void 0 : _p.deposit) !== null && _q !== void 0 ? _q : "", name: "extra_json[deposit]", error: "extra_json[deposit]" in errors, thousandSeparator: ",", rules: {
                    required: false,
                }, prefix: "$", control: control })),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericNumberInput, { label: "Commission Percentage", defaultValue: (_s = (_r = app === null || app === void 0 ? void 0 : app.extra_data_json) === null || _r === void 0 ? void 0 : _r.commission_percentage) !== null && _s !== void 0 ? _s : "", name: "extra_json[commission_percentage]", error: "extra_json[commission_percentage]" in errors, rules: {
                    required: false,
                }, suffix: "%", control: control })),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(NumberFormat, { value: commissionValue, thousandSeparator: ",", isNumericString: true, prefix: "Commission Value: $", decimalScale: 2, displayType: "text" }))));
};
export default FinancialInfoSection;
