import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import LeadMemberInfoCard from "./LeadMemberInfoCard";
import LeadInfoCard from "./LeadInfoCard";
import LeadNoteCard from "./LeadNoteCard";
import LeadExtraInfo from "./LeadExtraInfo";
import ManageDocuments from "../../../../components/ManageDocuments";
import { hasAccessTo, hasPermission } from "../../../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import GenericSetDate from "../../../../components/GenericFormFields/GenericSetDate";
import SetDisposition from "../SetDisposition";
import LeadSetSpouse from "./LeadSetSpouse";
import GenericDeleteButton from "../../../../components/GenericFormFields/GenericDeleteButton";
import ModalTransferUser from "../../../../components/Modals/ModalTransferUser";
import LeadMerge from "./LeadMerge";
import ModalQuote from "../../../../components/Modals/ModalQuote";
import ModalNotification from "../../../../components/Modals/ModalNotification";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        leadRow: {
            paddingTop: ".75em",
        },
    });
});
var MemberLeadDetail = function (_a) {
    var member = _a.member, lead = _a.lead, agents = _a.agents, states = _a.states, dispositions = _a.dispositions;
    var auth = usePage().props.auth;
    var classes = useStyles();
    var leadDispositionGroupIds = [1, 2];
    if ([5, 11].includes(lead === null || lead === void 0 ? void 0 : lead.lead_type_id)) {
        leadDispositionGroupIds.push(5);
    }
    var memberSpouseOptions = [
        { id: 0, name: "Member" },
        { id: 1, name: "Spouse" },
    ];
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "1px #035399 solid",
            borderRight: "1px #035399 solid",
            borderBottom: "1px #035399 solid",
            borderTop: "1px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 4, className: classes.leadRow },
            React.createElement(LeadMemberInfoCard, { member: member, lead: lead, states: states })),
        React.createElement(Grid, { xs: 4, className: classes.leadRow },
            React.createElement(LeadInfoCard, { lead: lead })),
        React.createElement(Grid, { xs: 4, className: classes.leadRow },
            React.createElement(LeadNoteCard, { lead: lead })),
        React.createElement(Grid, { xs: 4, className: classes.leadRow },
            React.createElement(LeadExtraInfo, { extraInfo: lead.extra_data_json, lead: lead })),
        React.createElement(Grid, { xs: 4, className: classes.leadRow },
            React.createElement(ManageDocuments, { resourceType: "leads", resource: lead })),
        React.createElement(Grid, { xs: 4, className: classes.leadRow }, lead.disposition_id != 26 /* lead is not Merged */ && (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1, mt: 3 },
                    React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Lead Options"))),
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { m: 1 },
                    React.createElement(ModalTransferUser, { agents: agents, current_transferred: lead.user_transferred, created_by: lead.user_created, lead_type_id: lead.lead_type.id, model: "lead", model_id: lead.id })),
                (lead === null || lead === void 0 ? void 0 : lead.disposition_id) == 2 &&
                    (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                    hasAccessTo(auth, lead) &&
                    hasPermission(auth, "edit apps") && (React.createElement(Box, { m: 1 },
                    React.createElement(Link, { href: route("app_form", [lead.id]), as: "span" },
                        React.createElement(Button, { variant: "contained", fullWidth: true, size: "small", style: {
                                backgroundColor: "#3EBF3E",
                                color: "#FFF",
                            } }, "Create App")))),
                (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                    hasAccessTo(auth, lead) &&
                    hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                    React.createElement(Link, { href: route("edit_lead_form", [
                            lead.id,
                        ]), as: "span" },
                        React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true, size: "small" }, "Edit Lead")))),
                (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                    hasPermission(auth, "delete leads") && (React.createElement(Box, { m: 1 },
                    React.createElement(GenericDeleteButton, { deleteRoute: "/lead/delete/" + lead.id, variant: "contained", label: "lead", id: lead.id }))),
                (lead === null || lead === void 0 ? void 0 : lead.status) == "applied" &&
                    (member === null || member === void 0 ? void 0 : member.spouse_first_name) &&
                    hasAccessTo(auth, lead) &&
                    hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                    React.createElement(LeadSetSpouse, { data: lead, options: memberSpouseOptions, title: "Set Member/Spouse", buttonName: "Set Member/Spouse", label: "Set Member/Spouse", url: "/lead/set-member-spouse", id: lead.id, name: "member_spouse" }))),
                (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                    hasAccessTo(auth, lead) &&
                    hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                    React.createElement(GenericSetDate, { data: lead, title: "Follow Up Date", buttonName: "Follow Up Date", label: "Follow Up Date", url: "/lead/set-follow-up-date", id: lead.id, name: "follow_up_date" }))),
                (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                    lead.lead_type_id == 11 &&
                    hasAccessTo(auth, lead) &&
                    hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                    React.createElement(ModalQuote, { lead_id: lead.id }))),
                (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                    lead.lead_type_id == 11 &&
                    [13, 27].includes(lead.disposition_id) && //only after sending quick quote/quote info
                    hasAccessTo(auth, lead) &&
                    hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                    React.createElement(ModalNotification, { notification_args: [lead.id], notification_method: "send_rosemark_quote_follow_up", notification_modal_text: "Send Quote Follow Up", notification_button_text: "Send Quote Follow Up", button_color: "#F48024", fullWidth: true }))),
                (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                    hasAccessTo(auth, lead) &&
                    hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                    React.createElement(SetDisposition, { data: lead, options: dispositions.filter(function (el) {
                            return leadDispositionGroupIds.includes(Number(el.disposition_group_id));
                        }), title: "Disposition", buttonName: "Disposition", label: "Dipositions", url: "/lead/set-disposition", id: lead.id, name: "disposition_id" }))),
                hasPermission(auth, "merge leads") &&
                    (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" && (React.createElement(Box, { m: 1 },
                    React.createElement(LeadMerge, { id: lead.id })))))))));
};
export default MemberLeadDetail;
