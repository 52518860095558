import React, { useState } from "react";
import Layout from "../Layout";
import { useForm } from "react-hook-form";
import { router } from "@inertiajs/react";
import { Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { throttle } from "lodash";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import GenericNumberInput from "../../components/GenericFormFields/GenericNumberInput";
import GenericRouteButton from "../../components/GenericRouteButton";
var EditProduct = function (_a) {
    var _b, _c, _d, _e, _f;
    var product = _a.product;
    console.log(product);
    var _g = useForm(), handleSubmit = _g.handleSubmit, errors = _g.formState.errors, control = _g.control;
    var _h = useState(true), menuOpen = _h[0], toggleMenuOpen = _h[1];
    var onSubmit = function (data) {
        data.id = product.id;
        router.post("/product/update", data);
    };
    return (React.createElement(Layout, { title: "Edit Product", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Typography, { variant: "h4" }, "Edit Product Form"))),
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericNumberInput, { control: control, name: "product_group_id", label: "Product Group Id", error: "product_group_id" in errors, rules: {
                                required: true,
                            }, defaultValue: (_b = product.product_group_id.toString()) !== null && _b !== void 0 ? _b : "1" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "name", label: "Product Name", error: "name" in errors, rules: {
                                required: true,
                            }, defaultValue: (_c = product.name) !== null && _c !== void 0 ? _c : "" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "description", label: "Product Description", error: "description" in errors, rules: {
                                required: true,
                            }, defaultValue: (_d = product.description) !== null && _d !== void 0 ? _d : "" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "subscription_code", label: "Subscription Code", error: "subscription_code" in errors, rules: {
                                required: true,
                            }, defaultValue: (_e = product.subscription_code) !== null && _e !== void 0 ? _e : "" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "subscription_months", label: "Subscription Months", error: "subscription_months" in errors, rules: {
                                required: true,
                            }, defaultValue: (_f = product.subscription_months) !== null && _f !== void 0 ? _f : "" }))),
                React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 4, lg: 3 },
                        React.createElement(GenericRouteButton, { route_name: "show_products", data: {}, buttonText: "Cancel", backgroundColor: "#E21D37", variant: "contained", buttonSize: "medium", fontSize: "14px", fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 4, lg: 3 },
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: throttle(handleSubmit(onSubmit), 2000), fullWidth: true }, "Save Product")))))));
};
export default EditProduct;
