import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { hasPermission } from "../../../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import ManageDocuments from "../../../../components/ManageDocuments";
import AddTaskButton from "../../../Task/AddTaskButton";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        donationRow: {
            paddingTop: ".75em",
        },
    });
});
var MemberDonationDetail = function (_a) {
    var member = _a.member, donation = _a.donation;
    var auth = usePage().props.auth;
    var classes = useStyles();
    return (React.createElement(Grid, { container: true, sx: {
            borderLeft: "1px #E21D37 solid",
            borderRight: "1px #E21D37 solid",
            borderBottom: "1px #E21D37 solid",
            borderTop: "1px #E21D37 solid",
            padding: "5px",
            borderRadius: "3px",
        }, xs: 12 },
        React.createElement(Grid, { xs: 6 },
            React.createElement(ManageDocuments, { resourceType: "donations", resource: donation })),
        React.createElement(Grid, { xs: 3 }),
        React.createElement(Grid, { xs: 3, className: classes.donationRow },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1, mt: 3 },
                    React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Donation Options"))),
            React.createElement(Grid, { xs: 12 },
                hasPermission(auth, "edit donations") && (React.createElement(Box, { m: 1 },
                    React.createElement(Link, { href: route("edit_donation", [donation.id]), as: "span" },
                        React.createElement(Button, { variant: "contained", fullWidth: true, size: "small", style: {
                                backgroundColor: "#df880e",
                                color: "#FFF",
                            } }, "Edit Donation")))),
                React.createElement(Box, { m: 1 },
                    React.createElement(AddTaskButton, { taskable_type: "Donation", taskable_id: donation.id, variant: "contained", color: "primary", fullWidth: true, size: "small" }))))));
};
export default MemberDonationDetail;
