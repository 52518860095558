import React, { useState } from "react";
import { router, Link } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import route from "ziggy-js";
import GenericControllerCheckboxInput from "../../components/GenericFormFields/CheckboxInput";
var AssignStatesForm = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useForm(), control = _c.control, handleSubmit = _c.handleSubmit, errors = _c.formState.errors;
    var states = response_data.states, user = response_data.user;
    var onSubmit = function (data) {
        data.user_id = user === null || user === void 0 ? void 0 : user.id;
        var selected_states = Object.entries(data.states).filter(function (state) { return state[1]; });
        data.states = [];
        selected_states.forEach(function (state) {
            data.states.push(parseInt(state[0]));
        });
        router.post("/user/abs/states/assign", data);
    };
    return (React.createElement(Layout, { title: "Assign Broker States", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement("form", { onSubmit: handleSubmit(onSubmit), style: { backgroundColor: "#f8fafc" } },
                React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                    !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                        React.createElement(DisplayErrors, { errors: serverErrors }))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Assign States")),
                    states ? (states.map(function (state) { return (React.createElement(Grid, { key: state.id, xs: 3 },
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: user.states.find(function (element) {
                                return element.name == state.name;
                            })
                                ? true
                                : false, control: control, name: "states[".concat(state.id, "]"), color: "primary", label: state.name }))); })) : (React.createElement(React.Fragment, null)),
                    React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("broker_carriers_states", [
                                    user === null || user === void 0 ? void 0 : user.id,
                                ]).toString() }, "Cancel")),
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Update States"))))))));
};
export default AssignStatesForm;
