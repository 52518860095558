import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import LeadMemberInfoCard from "../Lead/LeadMemberInfoCard";
import { hasAccessTo, hasPermission } from "../../../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import PolicyInfoCard from "./PolicyInfoCard";
import PolicyNoteCard from "./PolicyNoteCard";
import ManageDocuments from "../../../../components/ManageDocuments";
import GenericDeleteButton from "../../../../components/GenericFormFields/GenericDeleteButton";
import AddTaskButton from "../../../Task/AddTaskButton";
import ModalNotification from "../../../../components/Modals/ModalNotification";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        appRow: {
            paddingTop: ".75em",
        },
    });
});
var PolicyDetails = function (_a) {
    var member = _a.member, policy = _a.policy, leadTypes = _a.leadTypes, states = _a.states, insuranceCarriers = _a.insuranceCarriers;
    var auth = usePage().props.auth;
    var classes = useStyles();
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "1px #035399 solid",
            borderRight: "1px #035399 solid",
            borderBottom: "1px #035399 solid",
            borderTop: "1px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(LeadMemberInfoCard, { member: member, lead: policy, states: states })),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(PolicyInfoCard, { data: policy, insuranceCarriers: insuranceCarriers })),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(PolicyNoteCard, { data: policy, noteType: "policy" })),
        React.createElement(Grid, { xs: 4, className: classes.appRow }),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(ManageDocuments, { resourceType: "policies", resource: policy })),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { mx: 1, mt: 3, mb: 1 },
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Options"))),
                hasAccessTo(auth, policy) &&
                    hasPermission(auth, "edit policies") && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { ml: 1, mb: 1 },
                        React.createElement(Link, { href: route("edit_policy_form", [
                                policy.id,
                            ]), as: "span" },
                            React.createElement(Button, { variant: "outlined", color: "secondary", fullWidth: true, size: "small" }, "Edit Policy"))))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { ml: 1, mb: 1 },
                        React.createElement(AddTaskButton, { variant: "outlined", color: "primary", fullWidth: true, size: "small", taskable_id: policy.id, taskable_type: "policy" }))),
                [14, 15].includes(policy.insurance_type_id) &&
                    policy.status == "approved" && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { ml: 1, mb: 1 },
                        React.createElement(ModalNotification, { size: "small", notification_args: [
                                auth.user.id,
                                member.id,
                                policy.id,
                                policy.member_spouse,
                            ], fullWidth: true, button_color: "#1344ca", notification_method: "send_rosemark_issued_email", notification_modal_text: "Send Issued Email", notification_button_text: "Send Issued Email" })))),
                hasPermission(auth, "delete policies") && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { ml: 1, mb: 1 },
                        React.createElement(GenericDeleteButton, { deleteRoute: "/policy/delete/" + policy.id, label: "policy", variant: "contained", id: policy.id }))))))));
};
export default PolicyDetails;
