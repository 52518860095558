import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { helperFunctions } from "../../../../Helpers";
var MemberContactCard = function (_a) {
    var _b, _c, _d;
    var member = _a.member;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Spouse/Family Member"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_b = member.spouse_full_name) !== null && _b !== void 0 ? _b : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, member.spouse_email && (React.createElement("a", { href: "mailto:" + member.spouse_email }, member.spouse_email)))))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_c = member.spouse_phone) !== null && _c !== void 0 ? _c : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_d = member.spouse_phone_alt) !== null && _d !== void 0 ? _d : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 }, member.spouse_birth_date && (React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body2" }, helperFunctions.formatDate(member.spouse_birth_date))))),
            React.createElement(Grid, { item: true, xs: 6 }, member.spouse_birth_date && (React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Age:",
                    " ",
                    helperFunctions.getAge(member.spouse_birth_date))))))));
};
export default MemberContactCard;
