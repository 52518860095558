import { Box, Paper, TableContainer, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import Layout from "../Layout";
import GenericRouteButton from "../../components/GenericRouteButton";
import { hasPermission } from "../../utils/utils";
import { usePage } from "@inertiajs/react";
var IndexProduct = function (_a) {
    var data = _a.data;
    var auth = usePage().props.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var rows = [];
    data.map(function (product) {
        var _a, _b, _c, _d, _e, _f, _g;
        var values = {
            id: (_a = product.id) !== null && _a !== void 0 ? _a : "",
            productGroupId: (_b = product.product_group_id) !== null && _b !== void 0 ? _b : "",
            name: (_c = product.name) !== null && _c !== void 0 ? _c : "",
            description: (_d = product.description) !== null && _d !== void 0 ? _d : "",
            subscriptionCode: (_e = product.subscription_code) !== null && _e !== void 0 ? _e : "",
            price: (_f = product.price) !== null && _f !== void 0 ? _f : "null",
            subscriptionMonths: (_g = product.subscription_months) !== null && _g !== void 0 ? _g : "null",
        };
        rows.push(values);
    });
    var columns = [
        {
            field: "",
            align: "left",
            headerAlign: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) {
                return hasPermission(auth, "edit products") ? (React.createElement(GenericRouteButton, { route_name: "edit_product", data: { id: rowValues.row.id }, buttonText: "Edit", backgroundColor: "#f2a22f" })) : (React.createElement("span", { className: "table-cell-trucate" }));
            },
        },
        {
            field: "ID",
            headerName: "ID",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.id },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.id))); },
        },
        {
            field: "productGroupId",
            headerName: "Product Group Id",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.productGroupId },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.productGroupId))); },
        },
        {
            field: "name",
            headerName: "Name",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.name },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.name))); },
        },
        {
            field: "subscriptionCode",
            headerName: "Subscription Code",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.subscriptionCode },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.subscriptionCode))); },
        },
        {
            field: "price",
            headerName: "Price",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.price },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.price))); },
        },
        {
            field: "subscriptionMonths",
            headerName: "Subscription Months",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            width: 50,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.subscriptionMonths },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.subscriptionMonths))); },
        },
    ];
    return (React.createElement(Layout, { title: "Product Index", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Box, { mt: 2 },
            React.createElement(Box, { mb: 2 },
                React.createElement(PageTitle, { title: "Products" })),
            React.createElement(Box, { mb: 2 }, hasPermission(auth, "edit products") && (React.createElement(GenericRouteButton, { route_name: "create_product", data: {}, buttonText: "Add Product", backgroundColor: "#14943F" }))),
            React.createElement(TableContainer, { component: Paper, elevation: 3 },
                React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 100, autoHeight: true, rowsPerPageOptions: [100] })))));
};
export default IndexProduct;
