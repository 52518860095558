import { Link } from "@inertiajs/react";
import { Button } from "@mui/material";
import React from "react";
import route from "ziggy-js";
var GenericRouteButton = function (_a) {
    var route_name = _a.route_name, data = _a.data, color = _a.color, backgroundColor = _a.backgroundColor, fontSize = _a.fontSize, buttonSize = _a.buttonSize, variant = _a.variant, fontweight = _a.fontweight, buttonText = _a.buttonText, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b;
    return (React.createElement(Link, { href: route(route_name, data), as: "span", style: {
            textDecoration: "none",
        } },
        React.createElement(Button, { variant: variant !== null && variant !== void 0 ? variant : "contained", fullWidth: fullWidth, style: {
                backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "#392869",
                color: color !== null && color !== void 0 ? color : "#FFF",
                fontSize: fontSize !== null && fontSize !== void 0 ? fontSize : "11px",
                fontWeight: fontweight !== null && fontweight !== void 0 ? fontweight : "400",
            }, size: buttonSize !== null && buttonSize !== void 0 ? buttonSize : "small" }, buttonText)));
};
export default GenericRouteButton;
