var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { router } from "@inertiajs/react";
import route from "ziggy-js";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
import { useForm } from "react-hook-form";
import GenericControllerSelectCheckbox from "../../../components/GenericFormFields/SelectCheckbox";
import GenericAutoComplete from "../../../components/GenericFormFields/GenericAutoComplete";
export default function ReferralReportForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var data = _a.data;
    var form_data = data.form_data, serverErrors = data.serverErrors, utmCampaignOptions = data.utm_campaigns, utmContentOptions = data.utm_contents, utmSourceOptions = data.utm_sources, utmMediumOptions = data.utm_mediums, utmObjectiveOptions = data.utm_objectives, productsOptions = data.products;
    var reportForm = {
        startDate: form_data === null || form_data === void 0 ? void 0 : form_data.start_date,
        endDate: form_data === null || form_data === void 0 ? void 0 : form_data.end_date,
        memberOrderType: (_b = form_data === null || form_data === void 0 ? void 0 : form_data.member_order_type) !== null && _b !== void 0 ? _b : Array(),
        utm_campaigns: (_c = form_data === null || form_data === void 0 ? void 0 : form_data.utm_campaigns) !== null && _c !== void 0 ? _c : Array(),
        utm_contents: (_d = form_data === null || form_data === void 0 ? void 0 : form_data.utm_contents) !== null && _d !== void 0 ? _d : Array(),
        utm_sources: (_e = form_data === null || form_data === void 0 ? void 0 : form_data.utm_sources) !== null && _e !== void 0 ? _e : Array(),
        utm_mediums: (_f = form_data === null || form_data === void 0 ? void 0 : form_data.utm_mediums) !== null && _f !== void 0 ? _f : Array(),
        utm_objectives: (_g = form_data === null || form_data === void 0 ? void 0 : form_data.utm_objectives) !== null && _g !== void 0 ? _g : Array(),
        products: (_h = form_data === null || form_data === void 0 ? void 0 : form_data.products) !== null && _h !== void 0 ? _h : Array(),
    };
    var _j = useForm(), handleSubmit = _j.handleSubmit, errors = _j.formState.errors, control = _j.control;
    var memberOrderTypes = [
        {
            id: "0",
            name: "New Members",
        },
        {
            id: "1",
            name: "Renewal",
        },
    ];
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        router.post(route("referrals-report"), data);
    };
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Start Date", name: "start_date", defaultValue: reportForm.startDate, error: "start_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "End Date", name: "end_date", defaultValue: reportForm.endDate, error: "end_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericControllerSelectCheckbox, { name: "member_order_type", control: control, label: "Member Order Type", options: memberOrderTypes, rules: {
                    required: false,
                }, error: "member_order_type" in errors, value: reportForm.memberOrderType })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_objectives", label: "UTM Objectives", options: utmObjectiveOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: reportForm.utm_objectives, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_objectives" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_sources", label: "UTM Sources", options: utmSourceOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: reportForm.utm_sources, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_sources" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_campaigns", label: "UTM Campaigns", options: utmCampaignOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: reportForm.utm_campaigns, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_campaigns" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_mediums", label: "UTM Mediums", options: utmMediumOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: reportForm.utm_mediums, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_mediums" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_contents", label: "UTM Contents", options: utmContentOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: reportForm.utm_contents, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_contents" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "products", label: "Products", options: productsOptions, control: control, value: reportForm.products, rules: {
                    required: false,
                }, limitTags: 1, error: "products" in errors })),
        React.createElement(Grid, { container: true, xs: 12, md: 3, alignContent: "center" },
            React.createElement(Button, { type: "button", onClick: handleSubmit(onSubmit), variant: "contained", color: "primary", fullWidth: true }, "Update"))));
}
