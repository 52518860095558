var helperFunctions = {
    getAge: function (birthdate) {
        if (!birthdate) {
            return "N/A";
        }
        if (isNaN(new Date(birthdate).getTime())) {
            return "N/A";
        }
        if (typeof birthdate !== "string") {
            var ageDifferenceMs = new Date(Date.now() - new Date(birthdate).getTime());
            return Math.abs(ageDifferenceMs.getUTCFullYear() - 1970);
        }
        var year = parseInt(birthdate.substring(0, 4));
        var month = parseInt(birthdate.substring(5, 7));
        var day = parseInt(birthdate.substring(8, 10));
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getUTCMonth() + 1;
        var currentDay = currentDate.getUTCDate();
        // You need to treat the cases where the year, month or day hasn't arrived yet.
        var age = currentYear - year;
        if (currentMonth > month) {
            return age;
        }
        else {
            if (currentDay >= day && currentMonth >= month) {
                return age;
            }
            else {
                age--;
                return age;
            }
        }
    },
    // There is now a dateUtils file, perhaps these can be moved there in the future.
    getDateTime: function (datetime) {
        if (!datetime) {
            return "N/A";
        }
        if (isNaN(new Date(datetime).getTime())) {
            return "N/A";
        }
        var date = new Date(datetime);
        return (date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString());
    },
    formatDate: function (date) {
        if (!date) {
            return "";
        }
        if (isNaN(new Date(date).getTime())) {
            return "";
        }
        var newDate = new Date(date + "T12:00:00");
        return newDate.toLocaleDateString("en-US", { timeZone: "UTC" });
    },
    formatDateDashes: function (date) {
        if (!date) {
            return "";
        }
        if (isNaN(new Date(date).getTime())) {
            return "";
        }
        var newDate = new Date(date + "T12:00:00");
        return newDate
            .toLocaleDateString("en-US", { timeZone: "UTC" })
            .replace(/\//g, "-");
    },
};
export { helperFunctions };
