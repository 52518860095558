import React from "react";
import { Typography } from "@mui/material";
import { Link } from "@inertiajs/react";
import route from "ziggy-js";
var MemberLink = function (_a) {
    var member_id = _a.member_id, member_name = _a.member_name, member_spouse_name = _a.member_spouse_name, typography_variant = _a.typography_variant;
    return (React.createElement(Link, { href: route("show_member", {
            id: member_id,
        }), style: {
            color: "#000",
        } },
        React.createElement(Typography, { variant: typography_variant !== null && typography_variant !== void 0 ? typography_variant : "body2", style: { fontWeight: "bold" } }, member_name
            ? member_name + " (Member)"
            : member_spouse_name
                ? member_spouse_name + " (Spouse)"
                : member_id)));
};
export default MemberLink;
