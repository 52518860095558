import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { router } from "@inertiajs/react";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
import { useForm } from "react-hook-form";
import GenericControllerSelectCheckbox from "../../../components/GenericFormFields/SelectCheckbox";
export default function ActivityForm(_a) {
    var data = _a.data;
    var form_data = data.form_data, serverErrors = data.serverErrors, utmCampaignOptions = data.utm_campaigns, utmContentOptions = data.utm_contents, utmSourceOptions = data.utm_sources, utmMediumOptions = data.utm_mediums, utmObjectiveOptions = data.utm_objectives, productsOptions = data.products;
    var reportForm = {
        startDate: form_data === null || form_data === void 0 ? void 0 : form_data.start_date,
        endDate: form_data === null || form_data === void 0 ? void 0 : form_data.end_date,
    };
    var _b = useForm(), handleSubmit = _b.handleSubmit, errors = _b.formState.errors, control = _b.control;
    var log_types = [
        {
            id: "abs_brokers",
            name: "abs_brokers",
        },
        {
            id: "agents",
            name: "agents",
        },
        {
            id: "donations",
            name: "donations",
        },
        {
            id: "financial_apps",
            name: "financial_apps",
        },
        {
            id: "free_import",
            name: "free_import",
        },
        {
            id: "hsp_import",
            name: "hsp_import",
        },
        {
            id: "leads",
            name: "leads",
        },
        {
            id: "life_apps",
            name: "life_apps",
        },
        {
            id: "member_merge",
            name: "member_merge",
        },
        {
            id: "members",
            name: "members",
        },
        {
            id: "orders",
            name: "orders",
        },
        {
            id: "policies",
            name: "policies",
        },
        {
            id: "users",
            name: "users",
        },
    ];
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var search_url = data.user.id
        ? "/user/agent/activity-log/" + data.user.id
        : "/user/agent/activity-log/";
    var onSubmit = function (data) {
        router.post(search_url, data);
    };
    return (React.createElement(Grid, { container: true, m: 1, spacing: 2 },
        React.createElement(Grid, { xs: 3 },
            React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Start Date", name: "start_date", defaultValue: reportForm.startDate, error: "start_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 3 },
            React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "End Date", name: "end_date", defaultValue: reportForm.endDate, error: "end_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 3 },
            React.createElement(GenericControllerSelectCheckbox, { name: "log_type", control: control, label: "Log Type", options: log_types, rules: {
                    required: false,
                }, error: "log_type" in errors, value: [] })),
        React.createElement(Grid, { alignContent: "center", xs: 3 },
            React.createElement(Button, { type: "button", onClick: handleSubmit(onSubmit), variant: "contained", color: "primary", fullWidth: true, size: "large", sx: { mt: 1 } }, "Update"))));
}
