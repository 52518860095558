import React from "react";
import { MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import countryList from "../../../constants/countries";
import GenericControllerDropdownInput from "../../../components/GenericFormFields/DropdownInput";
import GenericNumberInput from "../../../components/GenericFormFields/GenericNumberInput";
var PersonalInfo = function (_a) {
    var _b;
    var app = _a.app, control = _a.control, errors = _a.errors, states = _a.states;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Personal Information")),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Birth State", name: "birth_state_id", id: "birth_state_id_select", defaultValue: app.birth_state_id || "", rules: { required: false }, control: control, error: "birth_state_id" in errors, 
                        // shrinkLabel
                        placeHolder: "Select State", options: !!(states === null || states === void 0 ? void 0 : states.length) &&
                            states.map(function (state) { return (React.createElement(MenuItem, { key: state.id, value: state.id }, state.name)); }) })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Birth Country", name: "birth_country", id: "birth_country_select", control: control, defaultValue: app.birth_country || "United States", rules: { required: false }, error: "birth_country" in errors, options: countryList.map(function (country, index) { return (React.createElement(MenuItem, { key: index, value: country }, country)); }) })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericNumberInput, { name: "ssn", label: "SSN", defaultValue: (_b = app === null || app === void 0 ? void 0 : app.ssn) !== null && _b !== void 0 ? _b : "", rules: {
                            required: false,
                            validate: function (value) {
                                if (value.length == 0) {
                                    return true;
                                }
                                var regExp = /[#-]/g;
                                return (value.replace(regExp, "").length === 9);
                            },
                        }, mask: "#", format: "###-##-####", error: "ssn" in errors, placeholder: "###-##-####", control: control }))))));
};
export default PersonalInfo;
