import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Typography } from "@mui/material";
import { helperFunctions } from "../../Helpers";
import TaskNoteCard from "./TaskNoteCard";
import { TaskableLink } from "./TaskableLink";
import ModalTransferUser from "../../components/Modals/ModalTransferUser";
import ModalFollowUpDate from "../../components/Modals/ModalFollowUpDate";
import ModalStatus from "../../components/Modals/ModalStatus";
var Task = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var task = _a.task, agentsArray = _a.agentsArray, setAgentsArray = _a.setAgentsArray;
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Grid, null,
                    React.createElement(Typography, { variant: "subtitle1", style: { color: "#9e9e9e" }, display: "inline" },
                        "Task #",
                        task.id,
                        " -",
                        " "),
                    React.createElement(Typography, { display: "inline" },
                        React.createElement("strong", null, task.task_type.name))),
                React.createElement(React.Fragment, null,
                    "Task Parent: ",
                    React.createElement(TaskableLink, { task: task })),
                (task === null || task === void 0 ? void 0 : task.follow_up_date) && (React.createElement(Typography, { variant: "body2" },
                    "Follow Up:",
                    " ",
                    React.createElement("strong", null, helperFunctions.formatDateDashes(task === null || task === void 0 ? void 0 : task.follow_up_date)))),
                React.createElement(Typography, { variant: "body2" },
                    "Status: ",
                    React.createElement("strong", null, task === null || task === void 0 ? void 0 : task.status)))),
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { my: 2 },
                task.user_transferred && (React.createElement(Typography, { variant: "body2", mb: 0.5 },
                    "Transferred to:",
                    " ",
                    ((_b = task.user_transferred) === null || _b === void 0 ? void 0 : _b.deleted_at) == null ? (React.createElement("strong", null, (_c = task.user_transferred) === null || _c === void 0 ? void 0 : _c.name)) : (React.createElement("strong", null,
                        React.createElement("s", null, (_d = task.user_transferred) === null || _d === void 0 ? void 0 : _d.name))))),
                React.createElement(Typography, { variant: "body2" },
                    "Created by: ", (_e = task.user_created) === null || _e === void 0 ? void 0 :
                    _e.name),
                React.createElement(Typography, { variant: "body2", mb: 0.5 },
                    "Created at:",
                    " ",
                    helperFunctions.getDateTime(task.created_at)),
                React.createElement(Typography, { variant: "body2" },
                    "Updated by: ", (_f = task.user_updated) === null || _f === void 0 ? void 0 :
                    _f.name),
                React.createElement(Typography, { variant: "body2" },
                    "Updated at:",
                    " ",
                    helperFunctions.getDateTime(task.updated_at)))),
        React.createElement(Grid, { xs: 6 },
            React.createElement(Box, { my: 2 },
                React.createElement(TaskNoteCard, { task: task }))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 1 },
            React.createElement(Grid, null,
                React.createElement(ModalTransferUser, { key: "task_" + task.id + "_transfer_modal", agents: agentsArray, setAgentsArray: setAgentsArray, current_transferred: task.user_transferred, created_by: task.user_created, lead_type_id: 0, model: "task", model_id: task.id, size: "small" })),
            React.createElement(Grid, null,
                React.createElement(ModalFollowUpDate, { current_follow_up_date: (_g = task.follow_up_date) !== null && _g !== void 0 ? _g : undefined, model_id: task.id, model: "task" })),
            React.createElement(Grid, null,
                React.createElement(ModalStatus, { current_status: task.status, model_id: task.id, model: "task" })))));
};
export default Task;
