import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "@inertiajs/react";
import route from "ziggy-js";
import { helperFunctions } from "../../../../Helpers";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import PolicyDetails from "./PolicyDetails";
import { formatAppType, setLead } from "../../../../utils/utils";
import { upperFirst } from "lodash";
import { AppLink } from "../App/AppLink";
import { formattedTransferUserName } from "../../../../utils/agentUtils";
var PolicyCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    var member = _a.member, policy = _a.policy, leadTypes = _a.leadTypes, insuranceTypes = _a.insuranceTypes, insuranceCarriers = _a.insuranceCarriers, states = _a.states, _1 = _a.showDefault, showDefault = _1 === void 0 ? false : _1;
    var _2 = useState(showDefault), show = _2[0], setShow = _2[1];
    var leadType = setLead((_b = policy === null || policy === void 0 ? void 0 : policy.policyable) === null || _b === void 0 ? void 0 : _b.lead);
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Link, { href: (policy === null || policy === void 0 ? void 0 : policy.member_id)
                        ? route("show_member", {
                            id: policy.member_id,
                        })
                        : "#", style: {
                        color: "#000",
                    } },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, policy.member_spouse
                        ? "".concat((_c = member === null || member === void 0 ? void 0 : member.spouse_full_name) !== null && _c !== void 0 ? _c : "N/A", " (Spouse)")
                        : "".concat((_d = member === null || member === void 0 ? void 0 : member.full_name) !== null && _d !== void 0 ? _d : "N/A", " (Member)"))),
                React.createElement(Typography, { variant: "subtitle1", style: { color: "#9e9e9e" } },
                    "#",
                    policy.member_id),
                (policy === null || policy === void 0 ? void 0 : policy.follow_up_date) && (React.createElement(Typography, { variant: "body2" },
                    "Follow Up:",
                    " ",
                    React.createElement("strong", null, helperFunctions.formatDateDashes(policy === null || policy === void 0 ? void 0 : policy.follow_up_date)))))),
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2 },
                React.createElement(Typography, { variant: "body2" },
                    "Policy ID:",
                    React.createElement(Link, { href: "/member/policy/".concat(policy === null || policy === void 0 ? void 0 : policy.member_id, "/").concat(policy === null || policy === void 0 ? void 0 : policy.id) },
                        React.createElement("strong", null,
                            " ", policy === null || policy === void 0 ? void 0 :
                            policy.id))),
                React.createElement(Typography, { variant: "body2" },
                    "App ID:",
                    React.createElement(AppLink, { member_id: policy === null || policy === void 0 ? void 0 : policy.member_id, app_id: (_e = policy === null || policy === void 0 ? void 0 : policy.policyable) === null || _e === void 0 ? void 0 : _e.id, app_type: formatAppType(policy === null || policy === void 0 ? void 0 : policy.policyable_type) })),
                React.createElement(Typography, { variant: "body2" },
                    "Lead ID:",
                    React.createElement(Link, { href: "/member/lead/".concat(policy === null || policy === void 0 ? void 0 : policy.member_id, "/").concat((_f = policy === null || policy === void 0 ? void 0 : policy.policyable) === null || _f === void 0 ? void 0 : _f.lead_id) },
                        React.createElement("strong", null,
                            " ", (_g = policy === null || policy === void 0 ? void 0 : policy.policyable) === null || _g === void 0 ? void 0 :
                            _g.lead_id))),
                React.createElement(Typography, { variant: "body2" },
                    "Policy Number:",
                    React.createElement("strong", null,
                        " ", policy === null || policy === void 0 ? void 0 :
                        policy.policy_number)),
                React.createElement(Typography, { variant: "body2" },
                    "Lead Type:",
                    React.createElement("strong", null,
                        " ", (_h = leadTypes === null || leadTypes === void 0 ? void 0 : leadTypes.find(function (el) { return (el === null || el === void 0 ? void 0 : el.id) == leadType; })) === null || _h === void 0 ? void 0 :
                        _h.name)),
                React.createElement(Typography, { variant: "body2" },
                    (policy === null || policy === void 0 ? void 0 : policy.insurance_type_id) && React.createElement(React.Fragment, null, "Insurance Product: "),
                    React.createElement("strong", null,
                        " ", (_j = insuranceTypes === null || insuranceTypes === void 0 ? void 0 : insuranceTypes.find(function (el) {
                        return (el === null || el === void 0 ? void 0 : el.id) == (policy === null || policy === void 0 ? void 0 : policy.insurance_type_id);
                    })) === null || _j === void 0 ? void 0 :
                        _j.name)),
                React.createElement(Typography, { variant: "body2" },
                    (policy === null || policy === void 0 ? void 0 : policy.insurance_carrier_id) && (React.createElement(React.Fragment, null, "Insurance Carrier: ")),
                    React.createElement("strong", null,
                        " ", (_k = insuranceCarriers === null || insuranceCarriers === void 0 ? void 0 : insuranceCarriers.find(function (el) {
                        return (el === null || el === void 0 ? void 0 : el.id) == (policy === null || policy === void 0 ? void 0 : policy.insurance_carrier_id);
                    })) === null || _k === void 0 ? void 0 :
                        _k.name)))),
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(Box, { my: 2 },
                React.createElement(Typography, { variant: "body2" }, "Status: "),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("strong", null, upperFirst((_l = policy === null || policy === void 0 ? void 0 : policy.status) !== null && _l !== void 0 ? _l : ""))),
                React.createElement(Typography, { variant: "body2" }, "Rewrite/New Business:"),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("strong", null, upperFirst((_m = policy === null || policy === void 0 ? void 0 : policy.rewrite) !== null && _m !== void 0 ? _m : ""))),
                ![15].includes(policy.insurance_type_id) && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body2" }, "Annual Premium:"),
                    React.createElement(Typography, { variant: "body2" },
                        React.createElement("strong", null, (policy === null || policy === void 0 ? void 0 : policy.annual_premium)
                            ? "$" +
                                parseInt(policy === null || policy === void 0 ? void 0 : policy.annual_premium).toLocaleString("en-US")
                            : "")))),
                ![15].includes(policy.insurance_type_id) && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body2" }, "Payment modality:"),
                    React.createElement(Typography, { variant: "body2" },
                        React.createElement("strong", null, policy === null || policy === void 0 ? void 0 : policy.payment_method)))),
                [14].includes(policy.insurance_type_id) && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body2" }, "Face Value:"),
                    React.createElement(Typography, { variant: "body2" },
                        React.createElement("strong", null, ((_o = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _o === void 0 ? void 0 : _o.face_value)
                            ? "$" +
                                parseInt((_p = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _p === void 0 ? void 0 : _p.face_value).toLocaleString("en-US")
                            : "")))),
                [15].includes(policy.insurance_type_id) && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body2" }, "Deposit:"),
                    React.createElement(Typography, { variant: "body2" },
                        React.createElement("strong", null, ((_q = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _q === void 0 ? void 0 : _q.deposit)
                            ? "$" +
                                parseInt((_r = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _r === void 0 ? void 0 : _r.deposit).toLocaleString("en-US")
                            : "")))),
                [14, 15].includes(policy.insurance_type_id) && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body2" }, "Commission Percentage:"),
                    React.createElement(Typography, { variant: "body2" },
                        React.createElement("strong", null, ((_s = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _s === void 0 ? void 0 : _s.commission_percentage)
                            ? ((_t = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _t === void 0 ? void 0 : _t.commission_percentage) + "%"
                            : "")),
                    React.createElement(Typography, { variant: "body2" }, "Commission Value:"),
                    React.createElement(Typography, { variant: "body2" },
                        React.createElement("strong", null, ((_u = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _u === void 0 ? void 0 : _u.commission_value)
                            ? "$" +
                                parseInt((_v = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _v === void 0 ? void 0 : _v.commission_value).toLocaleString("en-US")
                            : "")))))),
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2 },
                policy.user_transferred && (React.createElement(Typography, { variant: "body2" },
                    "Transferred to:",
                    " ",
                    formattedTransferUserName((_w = policy.user_transferred) === null || _w === void 0 ? void 0 : _w.name, (_x = policy.user_transferred) === null || _x === void 0 ? void 0 : _x.deleted_at, (_y = policy.user_transferred) === null || _y === void 0 ? void 0 : _y.userable_type))),
                React.createElement(Typography, { variant: "body2" },
                    "Created by: ", (_z = policy.user_created) === null || _z === void 0 ? void 0 :
                    _z.name),
                React.createElement(Typography, { variant: "body2" },
                    "Created at:",
                    " ",
                    helperFunctions.getDateTime(policy.created_at)),
                React.createElement(Typography, { variant: "body2" },
                    "Updated by: ", (_0 = policy.user_updated) === null || _0 === void 0 ? void 0 :
                    _0.name),
                React.createElement(Typography, { variant: "body2" },
                    "Updated at:",
                    " ",
                    helperFunctions.getDateTime(policy.updated_at)))),
        React.createElement(Grid, { container: true, item: true, xs: 1, justifyContent: "center", alignContent: "center" },
            React.createElement(Box, { my: 2 },
                React.createElement(Button, { onClick: function () { return setShow(!show); } }, show ? (React.createElement(ArrowUpward, { style: {
                        fontSize: 50,
                        color: "#035399",
                        textDecoration: "none",
                    } })) : (React.createElement(ArrowDownward, { style: { fontSize: 50, color: "#035399" } }))))),
        show && (React.createElement(PolicyDetails, { member: member, policy: policy, leadTypes: leadTypes, states: states, insuranceCarriers: insuranceCarriers }))));
};
export default PolicyCard;
