import React, { useState } from "react";
import { MenuItem, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerDropdownInput from "../../../components/GenericFormFields/DropdownInput";
import { isEmpty } from "lodash";
var QuickLead = function (_a) {
    var _b, _c;
    var control = _a.control, errors = _a.errors, lead = _a.lead, leadTypes = _a.leadTypes;
    var _d = useState((_c = (_b = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _b === void 0 ? void 0 : _b.quick_lead_type) !== null && _c !== void 0 ? _c : ""), quickLeadId = _d[0], setQuickLeadId = _d[1];
    return (React.createElement(Grid, { container: true, spacing: 4 },
        React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Lead Type")),
            React.createElement(Grid, { xs: 12 },
                React.createElement(GenericControllerDropdownInput, { name: "extra_data_json[quick_lead_type]", label: "Lead Type", id: "quick-lead-type-select", rules: {
                        required: true,
                    }, control: control, error: "extra_data_json" in errors &&
                        "quick_lead_type" in errors.extra_data_json, defaultValue: quickLeadId, handleChange: function (e) {
                        return setQuickLeadId(e.target.value);
                    }, placeHolder: "Select Lead Type", options: leadTypes.map(function (leadType) {
                        if (isEmpty(lead) && [2, 3].includes(leadType.id)) {
                            return null;
                        }
                        if (!leadType.name.includes("Quick")) {
                            return (React.createElement(MenuItem, { key: leadType.id, value: parseInt(leadType.id) }, leadType.name));
                        }
                    }) })))));
};
export default QuickLead;
