import Grid from "@mui/material/Unstable_Grid2/";
import React from "react";
import GenericRadioInput from "../../../components/GenericFormFields/GenericRadioInput";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
var LifeQuestion = function (_a) {
    var _b;
    var control = _a.control, leadState = _a.leadState, errors = _a.errors, handleStateChange = _a.handleStateChange, name = _a.name, legend = _a.legend, rules = _a.rules;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericRadioInput, { error: !!((_b = errors.extra_data_json) === null || _b === void 0 ? void 0 : _b[name]), legend: legend, control: control, name: "extra_data_json[".concat(name, "]"), defaultValue: (leadState === null || leadState === void 0 ? void 0 : leadState[name]) == true ? "1" : "0", rules: rules, handleChange: handleStateChange })),
        React.createElement(Grid, { xs: 12, md: 6 }, (leadState === null || leadState === void 0 ? void 0 : leadState[name]) === "1" && (React.createElement(GenericControllerTextInput, { label: "Comments", multiline: true, type: "text", control: control, rules: { required: false }, defaultValue: (leadState === null || leadState === void 0 ? void 0 : leadState["".concat(name, "_comment")]) || "", name: "extra_data_json[".concat(name, "_comment]"), error: "comments" in errors })))));
};
export default LifeQuestion;
