var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Layout from "../Layout";
import { useForm } from "react-hook-form";
import { router } from "@inertiajs/react";
import Grid from "@mui/material/Unstable_Grid2";
import DefaultBtn from "../../components/DefaultBtn";
import route from "ziggy-js";
import LinkFormFields from "./components/LinkFormFields";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
export default function CategoryForm(_a) {
    var response_data = _a.response_data;
    var category = response_data.category;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useForm(), control = _c.control, handleSubmit = _c.handleSubmit;
    var _d = useState(isEmpty(category) ? [] : category.links), links = _d[0], setLinks = _d[1];
    var handleAddLinks = function () {
        var newLink = {
            name: "",
            url: "",
            link_category: category.id,
            id: uuid(),
        };
        var newLinks = links.concat(newLink);
        setLinks(newLinks);
    };
    var onSubmit = function (data) {
        if (isEmpty(category)) {
            router.post(route("add_category"), data);
        }
        else {
            router.post(route("edit_category", category.id), data);
        }
    };
    var handleDeleteLink = function (e) {
        var index = parseInt(e.currentTarget.dataset.index);
        var linkId = e.currentTarget.dataset.link_id;
        var linksClone = __spreadArray([], links, true);
        linksClone.splice(index, 1);
        setLinks(linksClone);
    };
    return (React.createElement(Layout, { title: isEmpty(category) ? "Add Category" : "Edit Category", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement("form", { onSubmit: handleSubmit(onSubmit), style: { backgroundColor: "#f8fafc" } },
            React.createElement("h3", null, isEmpty(category) ? "Add Category" : "Edit Category"),
            React.createElement(GenericControllerTextInput, { className: "login-input", variant: "outlined", label: "Category Name", type: "text", control: control, name: "name", defaultValue: !isEmpty(category) ? category.name : "" }),
            React.createElement("br", null),
            links &&
                links.map(function (link, index) {
                    return (React.createElement("div", { key: link.id },
                        React.createElement("br", null),
                        React.createElement(LinkFormFields, { index: index, link: link, control: control, handleDeleteLink: handleDeleteLink })));
                }),
            React.createElement("br", null),
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(DefaultBtn, { type: "button", variant: "contained", onClick: handleAddLinks, color: "primary" }, "Add Link")),
                React.createElement("br", null),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(DefaultBtn, { type: "submit", variant: "contained", color: "primary" },
                        !isEmpty(category) ? "Edit" : "Add",
                        " Category"))))));
}
var EditCategoryFields = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n"], ["\n    display: flex;\n    align-items: center;\n"])));
var templateObject_1;
