import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import NumberFormat from "react-number-format";
import { getStateById } from "../utils/utils";
import { helperFunctions } from "../Helpers";
import MemberLink from "./MemberLink";
import MemberStatusIcon from "./MemberStatusIcon";
import MemberNav from "../Pages/Member/components/Navigation/MemberNav";
var FormMemberInfo = function (_a) {
    var _b, _c;
    var member = _a.member, spouseInfo = _a.spouseInfo, _d = _a.useMemberNav, useMemberNav = _d === void 0 ? false : _d, states = _a.states;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2, component: Paper, sx: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 12, md: 0.5, container: true, alignItems: "center", justifyContent: "right" },
            React.createElement(MemberStatusIcon, { status: member.status })),
        React.createElement(Grid, { container: true, xs: 12, md: 11.5 },
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(MemberLink, { member_id: member === null || member === void 0 ? void 0 : member.id, member_name: spouseInfo ? undefined : member.full_name, member_spouse_name: spouseInfo ? member.spouse_full_name : undefined, typography_variant: "h6" }),
                React.createElement(Typography, { variant: "body2" }, "# ".concat(member.id)),
                React.createElement(Typography, { variant: "body2" },
                    "DOB:",
                    " ",
                    React.createElement("strong", null, spouseInfo
                        ? helperFunctions.formatDate(member === null || member === void 0 ? void 0 : member.spouse_birth_date)
                        : helperFunctions.formatDate(member === null || member === void 0 ? void 0 : member.birth_date))),
                React.createElement(Typography, { variant: "body2" },
                    "Age:",
                    " ",
                    React.createElement("strong", null, spouseInfo
                        ? helperFunctions.getAge(member === null || member === void 0 ? void 0 : member.spouse_birth_date)
                        : helperFunctions.getAge(member === null || member === void 0 ? void 0 : member.birth_date))),
                React.createElement(Typography, { variant: "body2" },
                    "Email:",
                    " ",
                    React.createElement("strong", null, spouseInfo ? member.spouse_email : member.email)),
                React.createElement(Typography, { variant: "body2" },
                    "Phone:",
                    " ",
                    React.createElement(NumberFormat, { value: spouseInfo ? member.spouse_phone : member.phone, displayType: "text", format: "(###) ###-####", renderText: function (value) { return React.createElement("strong", null, value); } }))),
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "h6" }, "Mailing Address")),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("span", null, member.ship_address_1),
                    React.createElement("br", null),
                    React.createElement("span", null, member.ship_address_2 ? member.ship_address_2 : ""),
                    member.ship_address_2 ? React.createElement("br", null) : "",
                    React.createElement("span", null,
                        (member === null || member === void 0 ? void 0 : member.ship_city) ? member.res_city + "," : "",
                        React.createElement("strong", null, " ".concat((_b = getStateById(member.ship_state_id, states)) === null || _b === void 0 ? void 0 : _b.name, " ")),
                        member.ship_zip))),
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "h6" }, "Residential Address")),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("span", null, member.res_address_1),
                    React.createElement("br", null),
                    React.createElement("span", null, member.res_address_2 ? member.res_address_2 : ""),
                    member.res_address_2 ? React.createElement("br", null) : "",
                    React.createElement("span", null,
                        (member === null || member === void 0 ? void 0 : member.res_city) ? member.res_city + "," : "",
                        React.createElement("strong", null, " ".concat((_c = getStateById(member.res_state_id, states)) === null || _c === void 0 ? void 0 : _c.name, " ")),
                        member.res_zip)))),
        useMemberNav && (React.createElement(MemberNav, { member: member, order_count: member === null || member === void 0 ? void 0 : member.orders_count, lead_count: member === null || member === void 0 ? void 0 : member.leads_count, app_count: member === null || member === void 0 ? void 0 : member.apps_count, policy_count: member === null || member === void 0 ? void 0 : member.policies_count, document_count: member === null || member === void 0 ? void 0 : member.documents_count, donation_count: member === null || member === void 0 ? void 0 : member.donations_count, activity_log_count: member === null || member === void 0 ? void 0 : member.activities_count }))));
};
export default FormMemberInfo;
