import React, { useState } from "react";
import Layout from "../Layout";
import { useForm } from "react-hook-form";
import { router } from "@inertiajs/react";
import { Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { throttle } from "lodash";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import GenericNumberInput from "../../components/GenericFormFields/GenericNumberInput";
import GenericRouteButton from "../../components/GenericRouteButton";
export default function AddProduct(_a) {
    var _b = useForm(), handleSubmit = _b.handleSubmit, errors = _b.formState.errors, control = _b.control;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var onSubmit = function (data) {
        router.post("/product/store", data);
    };
    return (React.createElement(Layout, { title: "Add Product", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Typography, { variant: "h4" }, "Add Product Form"))),
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericNumberInput, { control: control, name: "product_group_id", label: "Product Group Id", error: "product_group_id" in errors, rules: {
                                required: true,
                            }, defaultValue: "1" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "name", label: "Product Name", error: "name" in errors, rules: {
                                required: true,
                            }, defaultValue: "" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "description", label: "Product Description", error: "description" in errors, rules: {
                                required: true,
                            }, defaultValue: "" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "subscription_code", label: "Subscription Code", error: "subscription_code" in errors, rules: {
                                required: true,
                            }, defaultValue: "" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "subscription_months", label: "Subscription Months", error: "subscription_months" in errors, rules: {
                                required: true,
                            }, defaultValue: "" })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericNumberInput, { control: control, name: "price", label: "Price", error: "price" in errors, rules: {
                                required: true,
                            }, defaultValue: "0" }))),
                React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 4, lg: 3 },
                        React.createElement(GenericRouteButton, { route_name: "show_products", data: {}, buttonText: "Cancel", backgroundColor: "#E21D37", variant: "contained", buttonSize: "medium", fontSize: "14px", fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 4, lg: 3 },
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: throttle(handleSubmit(onSubmit), 2000), fullWidth: true }, "Save Product")))))));
}
