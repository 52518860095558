import { router, usePage } from "@inertiajs/react";
import { Button, Checkbox, Container, FormControlLabel, MenuItem, Paper, Typography, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import route from "ziggy-js";
import GenericControllerDropdownInput from "../../components/GenericFormFields/DropdownInput";
import LeadSection from "../../components/LeadSection";
import Layout from "../Layout";
import LeadStatusContact from "./components/LeadStatusContact";
import FormMemberInfo from "../../components/FormMemberInfo";
import AgentInformation from "../App/components/AgentInformation";
import { throttle } from "lodash";
import { LeadReferral } from "./components/LeadReferral";
import ModalStatusProspect from "../../components/Modals/ModalStatusProspect";
export default function LeadForm(_a) {
    var _b, _c;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var disposition_group = response_data.disposition_group, lead = response_data.lead, agents = response_data.agents, medicare_change_reasons = response_data.medicare_change_reasons, medicare_plans = response_data.medicare_plans, insurance_types = response_data.insurance_types, member = response_data.member, referrals = response_data.referrals, states = response_data.states, lead_type_id = response_data.lead_type_id;
    lead.formType = "lead";
    var _d = useState(true), menuOpen = _d[0], toggleMenuOpen = _d[1];
    var _e = useState(false), submitting = _e[0], setSubmitting = _e[1];
    var _f = useForm(), handleSubmit = _f.handleSubmit, errors = _f.formState.errors, control = _f.control, clearErrors = _f.clearErrors, watch = _f.watch, resetField = _f.resetField;
    var _g = useState(lead_type_id !== null && lead_type_id !== void 0 ? lead_type_id : (((_b = lead === null || lead === void 0 ? void 0 : lead.lead_type) === null || _b === void 0 ? void 0 : _b.id) || "")), leadType = _g[0], setLeadType = _g[1];
    var _h = useState((lead === null || lead === void 0 ? void 0 : lead.member_spouse) ? true : false), spouseLead = _h[0], setSpouseLead = _h[1];
    var _j = useState((lead === null || lead === void 0 ? void 0 : lead.disposition_id) || ""), dispositionId = _j[0], setDispositionId = _j[1];
    var originalDispositionId = (_c = lead === null || lead === void 0 ? void 0 : lead.disposition_id) !== null && _c !== void 0 ? _c : "";
    var _k = useState(false), anotherCall = _k[0], setAnotherCall = _k[1];
    var auth = usePage().props.auth;
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var leadOptions = (member === null || member === void 0 ? void 0 : member.status) == "unmarketable"
        ? [{ disposition_group_id: 0, id: 16, name: "Donor Lead" }]
        : response_data === null || response_data === void 0 ? void 0 : response_data.lead_types;
    var onSubmit = function (data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (originalDispositionId !== dispositionId || anotherCall == true) {
            data.new_disposition_id = dispositionId;
        }
        data.lead_type_id = leadType;
        data.member_id = member.id;
        data.state_id = member.ship_state_id;
        data.zip = member.ship_zip;
        data.transfer_user_id = (_a = data.transfer_user_id) === null || _a === void 0 ? void 0 : _a.id;
        data.assisted_by = (_b = data.assisted_by) === null || _b === void 0 ? void 0 : _b.id;
        data.utm_source = response_data.use_member_referrals
            ? (_c = response_data.member) === null || _c === void 0 ? void 0 : _c.utm_source
            : (_d = data.referral) === null || _d === void 0 ? void 0 : _d.utm_source;
        data.utm_objective = response_data.use_member_referrals
            ? (_e = response_data.member) === null || _e === void 0 ? void 0 : _e.utm_objective
            : (_f = data.referral) === null || _f === void 0 ? void 0 : _f.utm_objective;
        data.utm_content = response_data.use_member_referrals
            ? (_g = response_data.member) === null || _g === void 0 ? void 0 : _g.utm_content
            : (_h = data.referral) === null || _h === void 0 ? void 0 : _h.utm_content;
        data.utm_medium = response_data.use_member_referrals
            ? (_j = response_data.member) === null || _j === void 0 ? void 0 : _j.utm_medium
            : (_k = data.referral) === null || _k === void 0 ? void 0 : _k.utm_medium;
        data.utm_campaign = response_data.use_member_referrals
            ? (_l = response_data.member) === null || _l === void 0 ? void 0 : _l.utm_campaign
            : (_m = data.referral) === null || _m === void 0 ? void 0 : _m.utm_campaign;
        var submitOptions = {
            onStart: function () {
                setSubmitting(true);
            },
            onFinish: function () {
                setSubmitting(false);
            },
        };
        if (isEmpty(lead)) {
            router.post(route("create_lead", member.id), data, submitOptions);
        }
        else {
            router.post(route("edit_lead", lead.id), data, submitOptions);
        }
    };
    var handleCancel = function () {
        router.get(route("show_member", [member.id]));
    };
    return (React.createElement(Layout, { title: isEmpty(lead) ? "Add Lead" : "Edit Lead", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { xs: 12, container: true, spacing: 2, direction: "column" },
                React.createElement(Grid, { container: true, xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12 },
                        React.createElement(Grid, { container: true, xs: 6, alignContent: "center", justifyContent: "flex-start" },
                            React.createElement(Typography, { variant: "h4", component: "h2" },
                                isEmpty(lead) ? "Add" : "Edit",
                                " Lead"),
                            (member === null || member === void 0 ? void 0 : member.status) == "unmarketable" && (React.createElement(Grid, { container: true, xs: true, justifyContent: "flex-end" },
                                React.createElement(Typography, { variant: "body1", component: "h3" }, "* Only Donation Leads can be added to unmarketable members"),
                                React.createElement(ModalStatusProspect, { member: member })))),
                        React.createElement(Grid, { xs: 6 },
                            React.createElement(GenericControllerDropdownInput, { name: "lead_form_id", id: "lead_form_select", label: "Lead Type", defaultValue: leadType, rules: {
                                    required: true,
                                }, control: control, handleChange: function (e) {
                                    setLeadType(e.target.value);
                                    clearErrors();
                                }, options: leadOptions.map(function (lead_type) {
                                    if (isEmpty(lead) &&
                                        [2, 3].includes(lead_type.id)) {
                                        return null;
                                    }
                                    return (React.createElement(MenuItem, { key: lead_type.id, value: lead_type.id }, lead_type.name.includes("Lead")
                                        ? lead_type.name
                                        : lead_type.name +
                                            " Lead"));
                                }) }))),
                    leadType !== "" && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
                                    React.createElement(Grid, { xs: 12 },
                                        React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Member Information")),
                                    (member === null || member === void 0 ? void 0 : member.spouse_full_name) && (React.createElement(Grid, { xs: 12 },
                                        React.createElement(FormControlLabel, { label: "Spouse/Family Member Lead?", control: React.createElement(Controller, { name: "member_spouse", control: control, defaultValue: spouseLead, render: function (_a) {
                                                    var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value, error = _a.fieldState.error;
                                                    return (React.createElement(Checkbox, { checked: spouseLead, onChange: function (e) {
                                                            setSpouseLead(e
                                                                .target
                                                                .checked);
                                                            onChange(e);
                                                        }, color: "primary" }));
                                                } }) }))),
                                    React.createElement(FormMemberInfo, { member: member, spouseInfo: spouseLead, states: states })))),
                        !(lead === null || lead === void 0 ? void 0 : lead.id) &&
                            !response_data.use_member_referrals && (React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
                                    React.createElement(LeadReferral, { referrals: referrals, control: control, data: lead, watch: watch, errors: errors }))))),
                        React.createElement(LeadSection, { errors: errors, control: control, lead: lead, leadType: leadType, insuranceTypes: insurance_types, medicarePlans: medicare_plans, medicareChangeReasons: medicare_change_reasons, responseData: response_data }),
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                                React.createElement(AgentInformation, { agents: agents, errors: errors, control: control, app: lead, deptType: leadType }))),
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                                    React.createElement(LeadStatusContact, { errors: errors, control: control, watch: watch, lead: lead, referrals: referrals, dispositionGroup: disposition_group, resetField: resetField, dispositionId: dispositionId, setDispositionId: setDispositionId, originalDispositionId: originalDispositionId, anotherCall: anotherCall, setAnotherCall: setAnotherCall })))),
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", xs: 12, spacing: 2 },
                                React.createElement(Grid, { xs: 3 },
                                    React.createElement(Button, { type: "button", variant: "outlined", color: "secondary", onClick: handleCancel, fullWidth: true }, "Cancel")),
                                React.createElement(Grid, { xs: 3 },
                                    React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: throttle(handleSubmit(onSubmit), 2000), disabled: submitting, fullWidth: true }, "Save Lead")))))))))));
}
