import React, { useState } from "react";
import { Box, Button, Divider, TableCell, TableRow } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { helperFunctions } from "../Helpers";
import { useForm } from "react-hook-form";
import route from "ziggy-js";
import { router, Link, usePage } from "@inertiajs/react";
import { Tab, Tabs } from "@mui/material";
import Note from "../components/Note";
import NotesForm from "../components/NotesForm";
import DefaultBtn from "../components/DefaultBtn";
import { startCase } from "lodash";
import FileUploadForm from "../components/FileUploadForm";
import orderBy from "lodash/orderBy";
import { hasOrderEditAccess, hasPermission } from "../utils/utils";
import GenericDeleteButton from "./GenericFormFields/GenericDeleteButton";
var OrderRow = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var order = _a.order;
    var auth = usePage().props.auth;
    var _t = useState(false), addNotesFlag = _t[0], setAddNotesFlag = _t[1];
    var sortedNotes = orderBy(order.order_notes, ["created_at"], "desc");
    var _u = useState(false), tab = _u[0], setTab = _u[1];
    var _v = useForm(), watch = _v.watch, handleSubmit = _v.handleSubmit, formErrors = _v.formState.errors;
    var _w = useState(false), openDelete = _w[0], setOpenDelete = _w[1];
    var _x = useState(0), curId = _x[0], setCurId = _x[1];
    var handleAddNotesFlag = function () {
        setAddNotesFlag(!addNotesFlag);
    };
    var handleTabChange = function (_, value) {
        if (tab === value) {
            return setTab(false);
        }
        setTab(value);
    };
    // delete
    var submitDelete = function () {
        setOpenDelete(false);
        router.post("/order/delete", {
            id: order.id,
        });
    };
    var handleCloseDelete = function () {
        setOpenDelete(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, { align: "left", className: "lead-tablecell" },
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Member ID:"),
                React.createElement(Box, null, (_b = order === null || order === void 0 ? void 0 : order.member_id) !== null && _b !== void 0 ? _b : "N/A"),
                (order === null || order === void 0 ? void 0 : order.usaepay_id) && (React.createElement(React.Fragment, null,
                    React.createElement(Box, { style: { fontWeight: "bold" } }, "Usaepay Transaction ID:"),
                    React.createElement(Box, null, order === null || order === void 0 ? void 0 : order.usaepay_id))),
                (order === null || order === void 0 ? void 0 : order.transaction_id) && (React.createElement(React.Fragment, null,
                    React.createElement(Box, { style: { fontWeight: "bold" } }, "Worldpay Transaction ID:"),
                    React.createElement(Box, null, order === null || order === void 0 ? void 0 : order.transaction_id))),
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Order ID:"),
                React.createElement(Box, null, order === null || order === void 0 ? void 0 : order.id),
                hasPermission(auth, "access tokenizedPAN") && (React.createElement(React.Fragment, null,
                    React.createElement(Box, { style: { fontWeight: "bold" } }, "TokenizedPAN:"),
                    React.createElement(Box, null, (_c = order === null || order === void 0 ? void 0 : order.TokenizedPAN) !== null && _c !== void 0 ? _c : "N/A")))),
            React.createElement(TableCell, { className: "lead-tablecell" },
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Cardholder Name"),
                React.createElement(Box, null, (_d = order === null || order === void 0 ? void 0 : order.card_holder) !== null && _d !== void 0 ? _d : "N/A"),
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Payment Type:"),
                React.createElement(Box, null, startCase(order === null || order === void 0 ? void 0 : order.payment_type)),
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Order Amount:"),
                React.createElement(Box, null,
                    "$", order === null || order === void 0 ? void 0 :
                    order.order_amount)),
            React.createElement(TableCell, { className: "lead-tablecell" },
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Product:"),
                React.createElement(Box, null, (_e = order === null || order === void 0 ? void 0 : order.product) === null || _e === void 0 ? void 0 : _e.name),
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Status:"),
                React.createElement(Box, null, startCase(order === null || order === void 0 ? void 0 : order.status)),
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Renewal:"),
                React.createElement(Box, null, (order === null || order === void 0 ? void 0 : order.renewal) ? "Yes" : "No")),
            React.createElement(TableCell, { className: "lead-tablecell" },
                React.createElement(Box, { style: { fontWeight: "bold" } }, "Referral:"),
                React.createElement(Box, null, (_f = order === null || order === void 0 ? void 0 : order.referral_name) !== null && _f !== void 0 ? _f : ""),
                React.createElement(Box, { style: { fontWeight: "bold" } }, "UTMs:"),
                React.createElement(Box, null, (_g = order === null || order === void 0 ? void 0 : order.utm_source) !== null && _g !== void 0 ? _g : ""),
                React.createElement(Box, null, (_h = order === null || order === void 0 ? void 0 : order.utm_campaign) !== null && _h !== void 0 ? _h : ""),
                React.createElement(Box, null, (_j = order === null || order === void 0 ? void 0 : order.utm_medium) !== null && _j !== void 0 ? _j : ""),
                React.createElement(Box, null, (_k = order === null || order === void 0 ? void 0 : order.utm_term) !== null && _k !== void 0 ? _k : ""),
                React.createElement(Box, null, (_l = order === null || order === void 0 ? void 0 : order.utm_content) !== null && _l !== void 0 ? _l : "")),
            React.createElement(TableCell, { className: "lead-tablecell" },
                React.createElement(Box, null,
                    "Created by:",
                    " ",
                    React.createElement("strong", null, (_m = order === null || order === void 0 ? void 0 : order.user_created) === null || _m === void 0 ? void 0 :
                        _m.first_name,
                        " ", (_o = order === null || order === void 0 ? void 0 : order.user_created) === null || _o === void 0 ? void 0 :
                        _o.last_name),
                    React.createElement(Box, null,
                        React.createElement("strong", null, helperFunctions.getDateTime(order.created_at)))),
                React.createElement(Box, null,
                    "Last updated by:",
                    " ",
                    React.createElement("strong", null, (_p = order === null || order === void 0 ? void 0 : order.user_updated) === null || _p === void 0 ? void 0 :
                        _p.first_name,
                        " ", (_q = order === null || order === void 0 ? void 0 : order.user_updated) === null || _q === void 0 ? void 0 :
                        _q.last_name),
                    React.createElement(Box, null,
                        React.createElement("strong", null, helperFunctions.getDateTime(order.updated_at))))),
            React.createElement(TableCell, null,
                hasOrderEditAccess(auth, order) &&
                    hasPermission(auth, "edit orders") ? (React.createElement(Box, { m: 1 },
                    React.createElement(Link, { href: route("edit_order_form", [order.id]), as: "span" },
                        React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true }, "Edit Order")))) : null,
                hasOrderEditAccess(auth, order) &&
                    hasPermission(auth, "delete orders") ? (React.createElement(Box, { m: 1 },
                    React.createElement(GenericDeleteButton, { deleteRoute: "/order/delete", label: "order", variant: "outlined", id: order.id }))) : null)),
        React.createElement(TableRow, { className: "lead-row" },
            React.createElement(TableCell, { className: "lead-row-padding", colSpan: 10 },
                React.createElement(Tabs, { value: tab, onChange: handleTabChange },
                    React.createElement(Tab, { label: React.createElement("strong", null, "Notes (" + sortedNotes.length + ")") }),
                    React.createElement(Tab, { label: React.createElement("strong", null, "Documents (" +
                            (order === null || order === void 0 ? void 0 : order.documents.length) +
                            ")") })),
                tab === 0 && (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Divider, { light: true })),
                    addNotesFlag && (React.createElement(Grid, { xs: 12 },
                        React.createElement(NotesForm, { noteOwner: order, noteType: "order", handleAddNotesFlag: handleAddNotesFlag }))),
                    !addNotesFlag && (React.createElement(Grid, { xs: 12 },
                        React.createElement(DefaultBtn, { type: "button", onClick: handleAddNotesFlag, variant: "outlined", color: "primary" }, "Add Note"))),
                    React.createElement(Grid, { xs: 12 }, sortedNotes.map(function (note) { return (React.createElement(Note, { key: note.id, note: note })); })))),
                tab === 1 && (React.createElement(React.Fragment, null,
                    React.createElement(FileUploadForm, { memberId: order === null || order === void 0 ? void 0 : order.member_id, userId: (_r = order === null || order === void 0 ? void 0 : order.transfer_user_id) !== null && _r !== void 0 ? _r : order === null || order === void 0 ? void 0 : order.created_by, name: "orders", id: order.id }),
                    React.createElement("ul", null, (_s = order === null || order === void 0 ? void 0 : order.documents) === null || _s === void 0 ? void 0 : _s.map(function (el) {
                        try {
                            return (React.createElement("li", { key: el.id },
                                React.createElement("a", { href: "/file/show?path=".concat(btoa(el.file_name)), target: "_blank" }, el.file_name)));
                        }
                        catch (e) {
                            return (React.createElement("li", { key: el.id }, el.file_name));
                        }
                    })))),
                React.createElement("hr", { className: "leads-table-divider" })))));
};
export default OrderRow;
