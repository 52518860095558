var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
var GenericControllerTextInput = function (_a) {
    var name = _a.name, label = _a.label, defaultValue = _a.defaultValue, control = _a.control, shrinkLabel = _a.shrinkLabel, rules = _a.rules, _b = _a.variant, variant = _b === void 0 ? "outlined" : _b, _c = _a.type, type = _c === void 0 ? "text" : _c, _d = _a.error, error = _d === void 0 ? false : _d, customOnChange = _a.customOnChange, isFileUpload = _a.isFileUpload, onblur = _a.onblur, options = __rest(_a, ["name", "label", "defaultValue", "control", "shrinkLabel", "rules", "variant", "type", "error", "customOnChange", "isFileUpload", "onblur"]);
    return (React.createElement(Controller, { control: control, name: name, defaultValue: defaultValue, rules: rules, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value;
            return (React.createElement(TextField, __assign({}, options, { variant: variant, label: label, type: type, error: error, onChange: customOnChange
                    ? function (e) {
                        customOnChange(e);
                        onChange(e);
                    }
                    : onChange, value: isFileUpload ? null : value, onBlur: typeof onblur !== "undefined"
                    ? function (e) {
                        onblur();
                        onBlur();
                    }
                    : onBlur, helperText: error && "This field is required.", InputLabelProps: { shrink: shrinkLabel }, fullWidth: true })));
        } }));
};
export default GenericControllerTextInput;
