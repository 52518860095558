import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Link } from "@inertiajs/react";
import { Button } from "@mui/material";
var MemberProfileLink = function (props) {
    var member = props.member;
    return (React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", xs: true, direction: "row", spacing: 2 },
        React.createElement(Grid, null,
            React.createElement(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, disabled: true }, "Success!")),
        React.createElement(Grid, null,
            React.createElement(Link, { href: "/member/show/".concat(member === null || member === void 0 ? void 0 : member.id) },
                React.createElement(Button, { type: "button", color: "primary", variant: "contained", fullWidth: true }, "Go To Member Profile")))));
};
export default MemberProfileLink;
