import React, { useState } from "react";
import { router } from "@inertiajs/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Typography, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import GenericControllerTextInput from "../GenericFormFields/TextInput";
var ModalSearchTokenizedPan = function (_a) {
    var _b = _a.button_color, button_color = _b === void 0 ? "#22D172" : _b, _c = _a.button_font, button_font = _c === void 0 ? "#FFF" : _c, _d = _a.size, size = _d === void 0 ? "small" : _d, _e = _a.fullWidth, fullWidth = _e === void 0 ? false : _e, _f = _a.variant, variant = _f === void 0 ? "contained" : _f;
    var _g = useState(false), openSearchModal = _g[0], setOpenSearchModal = _g[1];
    var _h = useForm(), control = _h.control, formErrors = _h.formState.errors, handleSubmit = _h.handleSubmit;
    var handleOpenSearch = function () {
        setOpenSearchModal(true);
    };
    var submitSearch = function (formData) {
        setOpenSearchModal(false);
        router.post("/order/tokenizedPAN", {
            token: formData.token,
        });
    };
    var handleCloseSearch = function () {
        setOpenSearchModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: handleOpenSearch, variant: variant, fullWidth: fullWidth, size: size, style: {
                backgroundColor: button_color,
                color: button_font,
            } }, "Search By TokenizedPAN"),
        React.createElement(Modal, { open: openSearchModal, onClose: handleCloseSearch },
            React.createElement(Dialog, { open: openSearchModal, onClose: handleCloseSearch },
                React.createElement(DialogTitle, null, "Search For a Member by tokenizedPAN"),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { xs: true },
                        React.createElement(Typography, { variant: "body1" }, "This will redirect to the member's profile page if it succeeds. Please allow up to a minute for this to finish running, do not click search multiple times.")),
                    React.createElement(Grid, { xs: true, mt: 1 },
                        React.createElement(Typography, { variant: "body1" }, "TokenizedPAN is not fixed in the database, it may change. If you are suspecting a token should work but does not, please verify that the token is indeed correct (no typos) and go through the proper channels if you still need to inquire about the token.")),
                    React.createElement(Grid, { xs: 12, mt: 2 },
                        React.createElement(GenericControllerTextInput, { control: control, type: "text", label: "TokenizedPAN", name: "token", rules: { required: true, min: 0 }, defaultValue: "", error: "token" in formErrors }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseSearch, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitSearch), color: "primary" }, "Search"))))));
};
export default ModalSearchTokenizedPan;
