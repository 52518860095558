var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import DefaultBtn from "../../../../components/DefaultBtn";
import PageTitle from "../../../../components/PageTitle";
import styled from "styled-components";
var columns = [
    {
        field: "name",
        headerName: "Recording",
        flex: 0.2,
        editable: false,
    },
    {
        field: "date",
        headerName: "Date",
        flex: 0.2,
        editable: false,
    },
    {
        field: "time",
        headerName: "Time",
        type: "string",
        flex: 0.2,
        editable: false,
        sortable: false,
    },
    {
        field: "size",
        headerName: "File Size (KB)",
        flex: 0.2,
    },
    {
        field: "download",
        headerName: "Download",
        flex: 0.2,
        sortable: false,
        renderCell: function (rowValues) {
            return (React.createElement("a", { href: "/recording/download/".concat(rowValues.row.url) },
                " ",
                React.createElement(DefaultBtn, { variant: "outlined", color: "primary", fullWidth: true, height: "30px" }, "Download")));
        },
    },
];
var MemberRecordings = function (_a) {
    var recordings = _a.recordings, member = _a.member;
    var rows = [];
    var id = 0;
    recordings.map(function (recording) {
        recording.id = id;
        rows.push(recording);
        id++;
    });
    return (React.createElement("div", { style: { width: 1010 } },
        React.createElement(PageTitle, { title: member.full_name + " Recordings" }),
        React.createElement("br", null),
        React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 20, rowsPerPageOptions: [20], density: "compact", autoHeight: true })));
};
var MemberRecordingsHeader = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-bottom: 20px;\n    color: #04539a;\n    font-style: italic;\n    font-weight: bold;\n"], ["\n    margin-bottom: 20px;\n    color: #04539a;\n    font-style: italic;\n    font-weight: bold;\n"])));
export default MemberRecordings;
var templateObject_1;
