import React, { useState } from "react";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "../Layout";
import DonationAddForm from "../Donation/DonationAddForm";
import FormMemberInfo from "../../components/FormMemberInfo";
var MembershipForm = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(), createdMember = _c[0], setCreatedMember = _c[1];
    var member = response_data.member, states = response_data.states, utm_sources = response_data.utm_sources, utm_campaigns = response_data.utm_campaigns, utm_mediums = response_data.utm_mediums, referrals = response_data.referrals, payment_options = response_data.payment_options;
    return (React.createElement(Layout, { title: "Add Donation", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, direction: "column", xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Add Donation")),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(FormMemberInfo, { member: member, states: states, spouseInfo: false })),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(DonationAddForm, { setCreatedMember: setCreatedMember, member: member, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, errors: serverErrors, referrals: referrals, payment_options: payment_options }))))));
};
export default MembershipForm;
