import React, { useState } from "react";
import { usePage } from "@inertiajs/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Link, Table, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
//
// This is used to connect with the
// Transfer User controller - starting with the tasks.
// See SetTransferUserId.tsx for the original functionality
//
var ModalActivity = function (_a) {
    var activity = _a.activity, setActivityArray = _a.setActivityArray, _b = _a.size, size = _b === void 0 ? "small" : _b;
    var auth = usePage().props.auth;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(0), member_id = _d[0], setMemberId = _d[1];
    var _e = useState(""), subject_line = _e[0], setSubjectLine = _e[1];
    var _f = useForm(), control = _f.control, formErrors = _f.formState.errors, handleSubmit = _f.handleSubmit;
    var getActivity = function () {
        axios
            .get("/activity/get/" + activity.id)
            .then(function (res) {
            var _a, _b;
            setMemberId((_a = res.data.activity.original.member_id) !== null && _a !== void 0 ? _a : null);
            setSubjectLine((_b = res.data.activity.original.subject_line) !== null && _b !== void 0 ? _b : "");
        })
            .then(function () { return setOpen(true); })
            .catch(function () {
            console.error("Error getting activities.");
        });
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: getActivity, variant: "contained", size: size, fullWidth: true, style: {
                backgroundColor: "#1871BF",
                color: "#FFF",
            } }, "View"),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, null,
                    "Activity #",
                    activity.id),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(TableContainer, { component: Paper, elevation: 3 },
                        React.createElement(Table, { "aria-label": "simple table", size: "small" },
                            React.createElement(TableHead, null,
                                member_id && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Link"),
                                    React.createElement(TableCell, null,
                                        React.createElement(Link, { href: "/member/show/" +
                                                member_id }, "Go To Member")))),
                                subject_line && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Summary"),
                                    React.createElement(TableCell, null, subject_line))),
                                activity.event && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Event"),
                                    React.createElement(TableCell, null, activity.event))),
                                activity.log_name && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Log Name"),
                                    React.createElement(TableCell, null, activity.log_name))),
                                activity.description && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Description"),
                                    React.createElement(TableCell, null, activity.description))),
                                activity.subject_type && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Subject Type"),
                                    React.createElement(TableCell, null, activity.subject_type))),
                                activity.subject_id && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Subject ID"),
                                    React.createElement(TableCell, null, activity.subject_id))),
                                activity.causer_type && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Causer Type"),
                                    React.createElement(TableCell, null, activity.causer_type))),
                                activity.causer_id && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Causer Id"),
                                    React.createElement(TableCell, null, activity.causer_id))),
                                activity.created_at && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: { fontWeight: "bold" } }, "Date Created"),
                                    React.createElement(TableCell, null, new Date(activity.created_at).toLocaleString()))),
                                activity.new_display && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: {
                                            fontWeight: "bold",
                                            verticalAlign: "top",
                                        } }, "New"),
                                    React.createElement(TableCell, { sx: { whiteSpace: "pre-wrap" } }, activity.new_display))),
                                activity.old_display && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { sx: {
                                            fontWeight: "bold",
                                            verticalAlign: "top",
                                        } }, "Old"),
                                    React.createElement(TableCell, { sx: { whiteSpace: "pre-wrap" } }, activity.old_display))))))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"))))));
};
export default ModalActivity;
