import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerCheckboxInput from "../../../components/GenericFormFields/CheckboxInput";
var FinancialLead = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var errors = _a.errors, control = _a.control, lead = _a.lead;
    var _v = useState(lead), leadState = _v[0], setLeadState = _v[1];
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
        React.createElement(Grid, { container: true, component: Paper, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Financial Strategies")),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_c = (_b = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _b === void 0 ? void 0 : _b.IRA_rollover_401k) !== null && _c !== void 0 ? _c : false, control: control, name: "extra_data_json[IRA_rollover_401k]", label: "401k - IRA Rollover" })),
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_e = (_d = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _d === void 0 ? void 0 : _d.annuities) !== null && _e !== void 0 ? _e : false, name: "extra_data_json[annuities]", control: control, label: "Annuities" })),
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_g = (_f = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _f === void 0 ? void 0 : _f.estate_planning) !== null && _g !== void 0 ? _g : false, name: "extra_data_json[estate_planning]", control: control, label: "Estate Planning" })),
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_j = (_h = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _h === void 0 ? void 0 : _h.income_tax_savings_techniques) !== null && _j !== void 0 ? _j : false, name: "extra_data_json[income_tax_savings_techniques]", control: control, label: "Income Tax Savings Techniques" })),
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_l = (_k = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _k === void 0 ? void 0 : _k.investment_portfolio_review) !== null && _l !== void 0 ? _l : false, name: "extra_data_json[investment_portfolio_review]", control: control, label: "Investment Portfolio Review" })),
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_o = (_m = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _m === void 0 ? void 0 : _m.life_insurance_review) !== null && _o !== void 0 ? _o : false, name: "extra_data_json[life_insurance_review]", control: control, label: "Life Insurance Review" })),
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_q = (_p = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _p === void 0 ? void 0 : _p.long_term_care_planning) !== null && _q !== void 0 ? _q : false, name: "extra_data_json[long_term_care_planning]", control: control, label: "Long Term Care Planning" })),
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_s = (_r = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _r === void 0 ? void 0 : _r.pension_options) !== null && _s !== void 0 ? _s : false, name: "extra_data_json[pension_options]", control: control, label: "Pension Options" })),
                React.createElement(Grid, { xs: 12, sm: 6, md: 4, lg: 3 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_u = (_t = leadState === null || leadState === void 0 ? void 0 : leadState.extra_data_json) === null || _t === void 0 ? void 0 : _t.retirement_planning) !== null && _u !== void 0 ? _u : false, name: "extra_data_json[retirement_planning]", control: control, label: "Retirement Planning" }))))));
};
export default FinancialLead;
