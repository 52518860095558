import React from "react";
import { MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerDropdownInput from "../../../components/GenericFormFields/DropdownInput";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
var DriversLicense = function (_a) {
    var _b, _c;
    var app = _a.app, control = _a.control, errors = _a.errors, states = _a.states;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Drivers License")),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Drivers License State", name: "drivers_license_state_id", id: "drivers_license_state_id", defaultValue: (_b = app === null || app === void 0 ? void 0 : app.drivers_license_state_id) !== null && _b !== void 0 ? _b : "", rules: { required: false }, error: "drivers_license_state_id" in errors, control: control, placeHolder: "Select State", options: !!(states === null || states === void 0 ? void 0 : states.length) &&
                            states.map(function (state) { return (React.createElement(MenuItem, { key: state.id, value: state.id }, state.name)); }) })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { label: "Drivers License Name", control: control, rules: { required: false }, name: "drivers_license_name", defaultValue: (_c = app === null || app === void 0 ? void 0 : app.drivers_license_name) !== null && _c !== void 0 ? _c : "", error: "drivers_license_name" in errors }))))));
};
export default DriversLicense;
