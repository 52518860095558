import { Box, Button, Grid, Paper, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../../../components/DisplayErrors";
import PolicyCard from "./PolicyCard";
import { filterDeletedPolicies, getShowDeleted } from "../../../../utils/utils";
import { MemberNavWrapper } from "../Navigation/MemberNavWrapper";
import { Alert } from "@mui/material";
import MemberHeader from "../MemberHeader";
var MemberPolicies = function (_a) {
    var _b;
    var response_data = _a.response_data, serverErrors = _a.errors, flash = _a.flash;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState(false), openMessage = _d[0], setOpenMessage = _d[1];
    var _e = useState(function () { return getShowDeleted(); }), showDeleted = _e[0], setShowDeleted = _e[1];
    var member = response_data.member, all_documents = response_data.all_documents, lead_types = response_data.lead_types, insurance_carriers = response_data.insurance_carriers, insurance_types = response_data.insurance_types, states = response_data.states, activity_log = response_data.activity_log;
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    useEffect(function () {
        localStorage.setItem("showDeleted", JSON.stringify(showDeleted));
    }, [showDeleted]);
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(MemberNavWrapper, { member: member, all_documents: all_documents, showDeleted: showDeleted, activity_log: activity_log }))),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(MemberHeader, { member: member })),
                React.createElement(Grid, { item: true, xs: 9 }),
                React.createElement(Grid, { item: true, xs: 12 }, openMessage && (React.createElement(Box, { mb: 1 },
                    React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                        React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message)))))),
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center" },
                React.createElement(Box, { mb: 1 },
                    React.createElement(Button, { variant: "outlined", style: {
                            backgroundColor: "#392869",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        }, size: "small", onClick: function () { return setShowDeleted(!showDeleted); } }, showDeleted ? "Hide Deleted" : "Show Deleted")))),
        React.createElement(Grid, { item: true, xs: 12 }, (_b = member === null || member === void 0 ? void 0 : member.policies) === null || _b === void 0 ? void 0 : _b.filter(function (el) {
            return filterDeletedPolicies(el, showDeleted);
        }).map(function (policy) { return (React.createElement(Box, { mb: 1, key: policy.id },
            React.createElement(Paper, null,
                React.createElement(PolicyCard, { member: member, policy: policy, leadTypes: lead_types, states: states, insuranceTypes: insurance_types, insuranceCarriers: insurance_carriers })))); }))));
};
export default MemberPolicies;
