import { router } from "@inertiajs/react";
import { Button, Container, MenuItem, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import route from "ziggy-js";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import { calculate_percentage, setLead } from "../../utils/utils";
import Layout from "../Layout";
import AgentInformation from "../App/components/AgentInformation";
import GenericControllerDropdownInput from "../../components/GenericFormFields/DropdownInput";
import GenericRadioInput from "../../components/GenericFormFields/GenericRadioInput";
import GenericNumberInput from "../../components/GenericFormFields/GenericNumberInput";
import FormMemberInfo from "../../components/FormMemberInfo";
import DisplayErrors from "../../components/DisplayErrors";
import { throttle } from "lodash";
import NumberFormat from "react-number-format";
export default function PolicyForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var member = response_data.member, policy = response_data.policy, app = response_data.app, lead = response_data.lead, lead_types = response_data.lead_types, agents = response_data.agents, insurance_carriers = response_data.insurance_carriers, insurance_types = response_data.insurance_types, referrals = response_data.referrals, states = response_data.states;
    var sorted_carriers = insurance_carriers.sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
    });
    var _x = useForm(), handleSubmit = _x.handleSubmit, errors = _x.formState.errors, control = _x.control, watch = _x.watch, setValue = _x.setValue, resetField = _x.resetField;
    var leadType = setLead(lead);
    if (!leadType) {
        switch ((_b = app === null || app === void 0 ? void 0 : app.app) === null || _b === void 0 ? void 0 : _b.appable_type) {
            case "App\\Models\\LifeInsuranceApplication":
                leadType = 11;
                break;
            case "App\\Models\\FinancialApplication":
                leadType = 5;
                break;
            case "App\\Models\\HealthInsuranceApplication":
                leadType = 13;
                break;
        }
    }
    policy.formType = "policy";
    policy.referral_id = (policy === null || policy === void 0 ? void 0 : policy.referral_id)
        ? policy === null || policy === void 0 ? void 0 : policy.referral_id
        : lead === null || lead === void 0 ? void 0 : lead.referral_id;
    policy.insurance_carrier_id = (policy === null || policy === void 0 ? void 0 : policy.insurance_carrier_id)
        ? policy === null || policy === void 0 ? void 0 : policy.insurance_carrier_id
        : app === null || app === void 0 ? void 0 : app.insurance_carrier_id;
    var defaultProductType = function () {
        var _a, _b;
        if (policy === null || policy === void 0 ? void 0 : policy.insurance_type_id)
            return policy === null || policy === void 0 ? void 0 : policy.insurance_type_id;
        //life
        if (leadType == 11)
            return 14;
        //financial
        if (leadType == 5)
            return 15;
        return ((_b = (_a = insurance_types.find(function (el) { var _a, _b; return el.name == ((_b = (_a = app === null || app === void 0 ? void 0 : app.lead) === null || _a === void 0 ? void 0 : _a.extra_data_json) === null || _b === void 0 ? void 0 : _b.insurance_product); })) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : "");
    };
    policy.insurance_type_id = defaultProductType();
    var _y = useState((_c = policy === null || policy === void 0 ? void 0 : policy.status) !== null && _c !== void 0 ? _c : ""), status = _y[0], setStatus = _y[1];
    var _z = useState(policy.insurance_type_id), insuranceTypeId = _z[0], setInsuranceTypeId = _z[1];
    var _0 = useState(true), menuOpen = _0[0], toggleMenuOpen = _0[1];
    var _1 = useState(false), submitting = _1[0], setSubmitting = _1[1];
    var _2 = useState(""), annualPremium = _2[0], setAnnualPremium = _2[1];
    var _3 = useState(""), modalPremium = _3[0], setModalPremium = _3[1];
    var _4 = useState((_e = (_d = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _d === void 0 ? void 0 : _d.commission_value) !== null && _e !== void 0 ? _e : ""), commissionValue = _4[0], setCommissionValue = _4[1];
    var _5 = useState(false), inputAnnual = _5[0], setInputAnnual = _5[1];
    var _6 = useState(false), inputModal = _6[0], setInputModal = _6[1];
    var watchModality = watch("payment_method", "");
    var watchModalPremium = watch("modal_premium", "");
    var watchAnnualPremium = watch("annual_premium", "");
    var watchCommissionPercentage = watch("extra_json[commission_percentage]", "");
    var watchDeposit = watch("extra_json[deposit]", "");
    var watchFaceValue = watch("extra_json[face_value]", "");
    var clearPremiums = function () {
        setInputAnnual(false);
        setInputModal(false);
        resetField("annual_premium", { defaultValue: "" });
        resetField("modal_premium", { defaultValue: "" });
        setAnnualPremium("");
        setModalPremium("");
    };
    var updateCommissionValue = function () {
        if (watchCommissionPercentage != "" && watchDeposit != "") {
            var value = calculate_percentage(watchDeposit, watchCommissionPercentage);
            setValue("extra_json[commission_value]", value);
            setCommissionValue(value);
        }
        else if (watchCommissionPercentage != "" && watchFaceValue != "") {
            var value = calculate_percentage(watchFaceValue, watchCommissionPercentage);
            setValue("extra_json[commission_value]", value);
            setCommissionValue(value);
        }
        else if ((watchCommissionPercentage == "" || watchDeposit == "") &&
            commissionValue != "") {
            var value = "";
            setValue("extra_json[commission_value]", value);
            setCommissionValue(value);
        }
    };
    useEffect(function () {
        if ([14, 15].includes(policy.insurance_type_id)) {
            updateCommissionValue();
        }
    }, [watchCommissionPercentage, watchDeposit, watchFaceValue]);
    var updateAnnualPremium = function (modal_premium) {
        var prevPremium = modalPremium.toString().length;
        var newPremium = modal_premium.toString().length;
        if (prevPremium > 0 && newPremium == 0) {
            clearPremiums();
            return;
        }
        setValue("modal_premium", modal_premium.toString());
        setModalPremium(modal_premium * 1);
        setInputModal(true);
        if (watchModality != "") {
            switch (watchModality) {
                case "Monthly":
                    setValue("annual_premium", (modal_premium * 12).toString());
                    setAnnualPremium(modal_premium * 12);
                    break;
                case "Quarterly":
                    setValue("annual_premium", (modal_premium * 4).toString());
                    setAnnualPremium(modal_premium * 4);
                    break;
                case "Semi-Annually":
                    setValue("annual_premium", (modal_premium * 2).toString());
                    setAnnualPremium(modal_premium * 2);
                    break;
                case "Annually":
                    setValue("annual_premium", modal_premium.toString());
                    setAnnualPremium(modal_premium);
                    break;
                default:
                    break;
            }
        }
    };
    var updateModalPremium = function (annual_premium) {
        var prevPremium = annualPremium.toString().length;
        var newPremium = annual_premium.toString().length;
        if (prevPremium > 0 && newPremium == 0) {
            clearPremiums();
            return;
        }
        setValue("annual_premium", annual_premium.toString());
        setAnnualPremium(annual_premium * 1);
        setInputAnnual(true);
        if (watchModality != "") {
            switch (watchModality) {
                case "Monthly":
                    setValue("modal_premium", (annual_premium / 12).toString());
                    setModalPremium(annual_premium / 12);
                    break;
                case "Quarterly":
                    setValue("modal_premium", (annual_premium / 4).toString());
                    setModalPremium(annual_premium / 4);
                    break;
                case "Semi-Annually":
                    setValue("modal_premium", (annual_premium / 2).toString());
                    setModalPremium(annual_premium / 2);
                    break;
                case "Annually":
                    setValue("modal_premium", annual_premium.toString());
                    setModalPremium(annual_premium);
                    break;
                default:
                    break;
            }
        }
    };
    var updateModality = function () {
        if (watchModality == "") {
            clearPremiums();
            return;
        }
        if (inputAnnual && typeof annualPremium == "number") {
            updateModalPremium(annualPremium);
        }
        if (inputModal && typeof modalPremium == "number") {
            updateAnnualPremium(modalPremium);
        }
    };
    useEffect(function () {
        if (!policy.id) {
            updateModality();
        }
    }, [watchModality]);
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        var _a, _b, _c, _d;
        data.member_id = member.id;
        data.app_id = app.id;
        data.member_spouse = app.member_spouse;
        data.lead_type_id = leadType;
        data.policy_holder = data.name;
        data.transfer_user_id = (_a = data.transfer_user_id) === null || _a === void 0 ? void 0 : _a.id;
        data.referral_id = (_b = data.referral) === null || _b === void 0 ? void 0 : _b.id;
        data.referral_name = (_c = data.referral) === null || _c === void 0 ? void 0 : _c.name;
        data.assisted_by = (_d = data.assisted_by) === null || _d === void 0 ? void 0 : _d.id;
        var submitOptions = {
            onStart: function () {
                setSubmitting(true);
            },
            onFinish: function () {
                setSubmitting(false);
            },
        };
        if (!policy.id) {
            router.post(route("create_policy"), data, submitOptions);
        }
        else {
            router.post(route("edit_policy", policy.id), data, submitOptions);
        }
    };
    var handleCancel = function () { return router.get(route("show_member", member.id)); };
    return (React.createElement(Layout, { title: "Policy", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { xs: 12, container: true, spacing: 2, direction: "column" },
                React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                    React.createElement(Grid, { spacing: 2, container: true, xs: 12, md: 6, justifyContent: "flex-start" },
                        React.createElement(Typography, { variant: "h4", component: "h2" }, policy.id
                            ? "Edit Policy"
                            : "Convert to Policy")),
                    React.createElement(Grid, { spacing: 2, container: true, xs: 12, md: 6, justifyContent: "flex-end" },
                        React.createElement(Typography, { variant: "h4", component: "h2" }, (_f = lead_types.filter(function (el) { return (el === null || el === void 0 ? void 0 : el.id) == leadType; })[0]) === null || _f === void 0 ? void 0 : _f.name))),
                React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                    serverErrors && (React.createElement(Grid, { spacing: 2, xs: 12 },
                        React.createElement(Grid, { container: true, xs: 12 },
                            React.createElement(DisplayErrors, { errors: serverErrors })))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { spacing: 2, xs: 12, container: true, component: Paper },
                                React.createElement(FormMemberInfo, { member: member, spouseInfo: app.member_spouse, states: states })))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { container: true, xs: 12, spacing: 2, component: Paper },
                                React.createElement(Grid, { xs: 12 },
                                    React.createElement(Typography, { variant: "h6", component: "h3", gutterBottom: true }, "Legal Name")),
                                React.createElement(Grid, { xs: 12 },
                                    React.createElement(GenericControllerTextInput, { name: "name", label: "Legal Name", control: control, defaultValue: policy.name
                                            ? policy.name
                                            : app.member_spouse
                                                ? member.spouse_full_name
                                                : member.full_name, rules: { required: true }, error: "name" in errors }))))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12, spacing: 2 },
                            React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                                React.createElement(Grid, { xs: 12 },
                                    React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Policy Information")),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericControllerTextInput, { type: "date", label: "Effective Date", name: "effective_date", control: control, rules: { required: true }, defaultValue: (_g = policy === null || policy === void 0 ? void 0 : policy.effective_date) !== null && _g !== void 0 ? _g : "", error: "effective_date" in errors, shrinkLabel: true })),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericControllerTextInput, { label: "Policy or Writing ID", rules: { required: true }, control: control, defaultValue: (_h = policy === null || policy === void 0 ? void 0 : policy.policy_number) !== null && _h !== void 0 ? _h : "", name: "policy_number", error: "policy_number" in errors, helperText: "This field is required." })),
                                React.createElement(Grid, { xs: 12, sm: 6 },
                                    React.createElement(GenericControllerDropdownInput, { name: "insurance_type_id", id: "insurance_type_id", label: "Insurance Type", rules: {
                                            required: true,
                                        }, control: control, defaultValue: insuranceTypeId, error: "insurance_type_id" in errors, handleChange: function (e) {
                                            return setInsuranceTypeId(e.target.value);
                                        }, options: insurance_types.map(function (insurance) { return (React.createElement(MenuItem, { key: "insurance_types_".concat(insurance.id), value: insurance.id }, insurance.name)); }) })),
                                React.createElement(Grid, { xs: 12, sm: 6 },
                                    React.createElement(GenericControllerDropdownInput, { name: "insurance_carrier_id", id: "insurance_carrier_id", label: "Insurance Carrier", rules: {
                                            required: true,
                                        }, control: control, defaultValue: (_j = policy === null || policy === void 0 ? void 0 : policy.insurance_carrier_id) !== null && _j !== void 0 ? _j : "", error: "insurance_carrier_id" in errors, handleChange: function (e) { }, options: sorted_carriers.map(function (insurance) { return (React.createElement(MenuItem, { key: "insurance_carriers_".concat(insurance.id), value: insurance.id }, insurance.name)); }) })),
                                React.createElement(Grid, { xs: 12, sm: 6 }),
                                ![5, 11].includes(leadType) && (React.createElement(Grid, { xs: 12, sm: 6 },
                                    React.createElement(GenericControllerDropdownInput, { name: "enrollment_period", id: "enrollment_period", label: "Enrollment Period", rules: {
                                            required: false,
                                        }, control: control, defaultValue: (_k = policy === null || policy === void 0 ? void 0 : policy.enrollment_period) !== null && _k !== void 0 ? _k : "", error: "enrollment_period" in
                                            errors, handleChange: function (e) { }, options: [
                                            "AEP",
                                            "GI",
                                            "IEP",
                                            "OE",
                                            "OEP",
                                            "SEP",
                                            "UW",
                                        ].map(function (enrollment_period) {
                                            return (React.createElement(MenuItem, { value: enrollment_period, key: enrollment_period }, enrollment_period));
                                        }) }))),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericRadioInput, { defaultValue: (_l = policy === null || policy === void 0 ? void 0 : policy.underwritten) !== null && _l !== void 0 ? _l : "", control: control, rules: { required: true }, name: "underwritten", legend: "Policy underwritten", error: "underwritten" in errors })),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericRadioInput, { defaultValue: (_m = policy === null || policy === void 0 ? void 0 : policy.docs) !== null && _m !== void 0 ? _m : "", control: control, rules: { required: true }, name: "docs", legend: "Docs", error: "docs" in errors })),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericRadioInput, { defaultValue: (_o = policy === null || policy === void 0 ? void 0 : policy.rewrite) !== null && _o !== void 0 ? _o : "", control: control, rules: { required: true }, name: "rewrite", legend: "Rewrite", yesLabel: "Rewrite", noLabel: "New Business", yesValue: "rewrite", noValue: "new business", error: "rewrite" in errors })),
                                ![15].includes(policy.insurance_type_id) ? (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { xs: 12, sm: 6 },
                                        React.createElement(GenericControllerDropdownInput, { name: "payment_method", id: "payment_method", label: "Payment Mode", rules: {
                                                required: true,
                                            }, control: control, defaultValue: (_p = policy === null || policy === void 0 ? void 0 : policy.payment_method) !== null && _p !== void 0 ? _p : "", error: "payment_method" in
                                                errors, handleChange: function (e) { }, options: [
                                                "Monthly",
                                                "Quarterly",
                                                "Semi-Annually",
                                                "Annually",
                                            ].map(function (payment_method) {
                                                return (React.createElement(MenuItem, { key: payment_method, value: payment_method }, payment_method));
                                            }) })),
                                    !policy.id ? (React.createElement(React.Fragment, null, watchModality != "" && (React.createElement(React.Fragment, null,
                                        watchAnnualPremium ==
                                            "" ||
                                            inputModal ? (React.createElement(Grid, { xs: 12, md: 6 },
                                            React.createElement(GenericNumberInput, { label: "Modal Premium", defaultValue: (policy === null || policy === void 0 ? void 0 : policy.modal_premium)
                                                    ? policy === null || policy === void 0 ? void 0 : policy.modal_premium
                                                    : modalPremium, customOnChange: updateAnnualPremium, name: "modal_premium", error: "modal_premium" in
                                                    errors, thousandSeparator: ",", rules: {
                                                    required: false,
                                                }, prefix: "$", control: control }))) : (React.createElement(Grid, { xs: 12, md: 6, display: "flex", alignItems: "center" },
                                            React.createElement(NumberFormat, { value: modalPremium, thousandSeparator: ",", isNumericString: true, prefix: "Modal Premium: $", decimalScale: 2, displayType: "text" }))),
                                        watchModalPremium ==
                                            "" ||
                                            inputAnnual ? (React.createElement(Grid, { xs: 12, md: 6 },
                                            React.createElement(GenericNumberInput, { label: "Annual Premium", defaultValue: (policy === null || policy === void 0 ? void 0 : policy.annual_premium)
                                                    ? policy === null || policy === void 0 ? void 0 : policy.annual_premium
                                                    : annualPremium, customOnChange: updateModalPremium, name: "annual_premium", error: "annual_premium" in
                                                    errors, thousandSeparator: ",", rules: {
                                                    required: false,
                                                }, isNumericString: true, prefix: "$", control: control }))) : (React.createElement(Grid, { xs: 12, md: 6, display: "flex", alignItems: "center" },
                                            React.createElement(NumberFormat, { value: annualPremium, thousandSeparator: ",", isNumericString: true, prefix: "Annual Premium: $", decimalScale: 2, displayType: "text" }))))))) : (React.createElement(React.Fragment, null,
                                        React.createElement(Grid, { xs: 12, md: 6 },
                                            React.createElement(GenericNumberInput, { label: "Modal Premium", defaultValue: (_q = policy === null || policy === void 0 ? void 0 : policy.modal_premium) !== null && _q !== void 0 ? _q : "", name: "modal_premium", error: "modal_premium" in
                                                    errors, thousandSeparator: ",", rules: {
                                                    required: false,
                                                }, prefix: "$", control: control })),
                                        React.createElement(Grid, { xs: 12, md: 6 },
                                            React.createElement(GenericNumberInput, { label: "Annual Premium", defaultValue: (_r = policy.annual_premium) !== null && _r !== void 0 ? _r : "", name: "annual_premium", error: "annual_premium" in
                                                    errors, thousandSeparator: ",", rules: {
                                                    required: false,
                                                }, isNumericString: true, prefix: "$", control: control })))))) : (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { xs: 12, sm: 6 },
                                        React.createElement(GenericNumberInput, { label: policy.insurance_type_id ==
                                                14 /* Life Insurance */
                                                ? "Face Value"
                                                : "Deposit", defaultValue: (_u = (policy.insurance_type_id ==
                                                14
                                                ? (_s = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _s === void 0 ? void 0 : _s.face_value
                                                : (_t = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _t === void 0 ? void 0 : _t.deposit)) !== null && _u !== void 0 ? _u : "", name: policy.insurance_type_id ==
                                                14
                                                ? "extra_json[face_value]"
                                                : "extra_json[deposit]", error: "extra_json[deposit]" in
                                                errors ||
                                                "extra_json[face_value]" in
                                                    errors, thousandSeparator: ",", rules: {
                                                required: false,
                                            }, prefix: "$", control: control })),
                                    React.createElement(Grid, { xs: 12, sm: 6 },
                                        React.createElement(GenericNumberInput, { label: "Commission Percentage", defaultValue: (_w = (_v = policy === null || policy === void 0 ? void 0 : policy.extra_data_json) === null || _v === void 0 ? void 0 : _v.commission_percentage) !== null && _w !== void 0 ? _w : "", name: "extra_json[commission_percentage]", error: "extra_json[commission_percentage]" in
                                                errors, rules: {
                                                required: false,
                                            }, suffix: "%", control: control })),
                                    React.createElement(Grid, { xs: 12, sm: 6 },
                                        React.createElement(NumberFormat, { value: commissionValue, thousandSeparator: ",", isNumericString: true, prefix: "Commission Value: $", decimalScale: 2, displayType: "text" }))))))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(AgentInformation, { agents: agents, errors: errors, control: control, app: policy, deptType: lead.lead_type_id })),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12, spacing: 2 },
                            React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                                React.createElement(Grid, { xs: 12 },
                                    React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Status")),
                                React.createElement(Grid, { xs: 12, md: 6 },
                                    React.createElement(GenericControllerDropdownInput, { name: "status", id: "status", label: "Status", rules: {
                                            required: true,
                                        }, control: control, defaultValue: status, error: "status" in errors, handleChange: function (e) {
                                            return setStatus(e.target.value);
                                        }, options: [
                                            {
                                                name: "In Process",
                                                id: "in_process",
                                            },
                                            {
                                                name: "Approved",
                                                id: "approved",
                                            },
                                            {
                                                name: "Rejected",
                                                id: "rejected",
                                            },
                                            {
                                                name: "Cancelled",
                                                id: "cancelled",
                                            },
                                        ].map(function (status) {
                                            return (React.createElement(MenuItem, { value: status.id, key: status.name }, status.name));
                                        }) })),
                                React.createElement(Grid, { xs: 12 },
                                    React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Note")),
                                React.createElement(Grid, { xs: 12 },
                                    React.createElement(GenericControllerTextInput, { label: "Policy Note", multiline: true, control: control, defaultValue: "", name: "policy_note_text", minRows: 10, error: "policy_note_text" in errors }))))),
                    React.createElement(Grid, { container: true, xs: 12, justifyContent: "center", spacing: 4 },
                        React.createElement(Grid, { xs: 3 },
                            React.createElement(Button, { type: "button", variant: "outlined", color: "secondary", onClick: handleCancel, fullWidth: true }, "Cancel")),
                        React.createElement(Grid, { xs: 3 },
                            React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: throttle(handleSubmit(onSubmit), 2000), disabled: submitting, fullWidth: true }, "Save Policy"))))))));
}
function setValue(annual_premium) {
    throw new Error("Function not implemented.");
}
