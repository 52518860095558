import { router } from "@inertiajs/react";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import route from "ziggy-js";
import GenericControllerDropdownInput from "../../components/GenericFormFields/DropdownInput";
import GenericPhoneInput from "../../components/GenericFormFields/GenericPhoneInput";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import Layout from "../Layout";
import SearchResults from "./SearchResults";
export default function SearchForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var response_data = _a.response_data;
    var states = response_data.states;
    var query = response_data.query;
    var _o = useState(true), menuOpen = _o[0], toggleMenuOpen = _o[1];
    var _p = useState(true), showForm = _p[0], toggleShowForm = _p[1];
    var _q = useState((_b = query === null || query === void 0 ? void 0 : query.state_id) !== null && _b !== void 0 ? _b : ""), stateId = _q[0], setStateId = _q[1];
    var _r = useState((_c = query === null || query === void 0 ? void 0 : query.status) !== null && _c !== void 0 ? _c : ""), status = _r[0], setStatus = _r[1];
    var _s = useForm(), handleSubmit = _s.handleSubmit, errors = _s.formState.errors, control = _s.control;
    var onSubmit = function (data) {
        router.post(route("advance-search"), data);
    };
    useEffect(function () {
        var _a;
        if (((_a = response_data === null || response_data === void 0 ? void 0 : response_data.members) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            toggleShowForm(false);
        }
    }, [response_data]);
    return (React.createElement(Layout, { title: "Edit Agent", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        showForm && (React.createElement("form", { onSubmit: handleSubmit(onSubmit), style: { backgroundColor: "#f8fafc" } },
            React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h6", component: "h3" }, "Member Search"),
                    React.createElement(Typography, { variant: "body1" }, "Fields below search for main and family member fields and/or residential and shipping fields."))),
            query && ((_d = response_data === null || response_data === void 0 ? void 0 : response_data.members) === null || _d === void 0 ? void 0 : _d.length) == 0 && (React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
                    React.createElement(Alert, { severity: "error" }, "No Result found for Current Query")))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, type: "text", label: "Member ID", name: "member_id", defaultValue: (_e = query === null || query === void 0 ? void 0 : query.member_id) !== null && _e !== void 0 ? _e : "", error: "member_id" in errors }))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(GenericControllerTextInput, { control: control, type: "text", label: "Full Name", name: "full_name", defaultValue: (_f = query === null || query === void 0 ? void 0 : query.full_name) !== null && _f !== void 0 ? _f : "", error: "full_name" in errors }))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, type: "text", label: "First Name", name: "first_name", defaultValue: (_g = query === null || query === void 0 ? void 0 : query.first_name) !== null && _g !== void 0 ? _g : "", error: "first_name" in errors })),
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, type: "text", label: "Last Name", name: "last_name", defaultValue: (_h = query === null || query === void 0 ? void 0 : query.last_name) !== null && _h !== void 0 ? _h : "", error: "last_name" in errors }))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, type: "text", label: "email", name: "email", defaultValue: (_j = query === null || query === void 0 ? void 0 : query.email) !== null && _j !== void 0 ? _j : "", error: "email" in errors })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericPhoneInput, { defaultValue: (_k = query === null || query === void 0 ? void 0 : query.phone) !== null && _k !== void 0 ? _k : "", label: "Phone", name: "phone", control: control, required: false, helperText: "Please provide full phone (###) ###-####." }))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Birth Date", name: "birth_date", defaultValue: (_l = query === null || query === void 0 ? void 0 : query.birth_date) !== null && _l !== void 0 ? _l : "", error: "birth_date" in errors, shrinkLabel: true })),
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Status", id: "status_select", defaultValue: status, handleChange: function (e) {
                            return setStatus(e.target.value);
                        }, control: control, name: "status", rules: {
                            required: false,
                        }, error: "status" in errors, placeHolderValue: "any", options: [
                            { name: "Active", val: "active" },
                            { name: "Inactive", val: "deleted" },
                            { name: "Pending", val: "pending" },
                            { name: "Prospect", val: "prospect" },
                        ].map(function (status) {
                            return (React.createElement(MenuItem, { key: status.val, value: status.val }, status.name));
                        }) }))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, type: "text", label: "Zip Code", name: "zip", defaultValue: (_m = query === null || query === void 0 ? void 0 : query.zip) !== null && _m !== void 0 ? _m : "", error: "zip" in errors, fullWidth: true })),
                React.createElement(Grid, { xs: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "State", id: "state_id_select", defaultValue: stateId, handleChange: function (e) {
                            return setStateId(e.target.value);
                        }, rules: {
                            required: false,
                        }, control: control, name: "state_id", error: "state_id" in errors, options: !!(states === null || states === void 0 ? void 0 : states.length) &&
                            states.map(function (state) { return (React.createElement(MenuItem, { key: state.id, value: state.id }, state.name)); }) }))),
            React.createElement(Grid, { container: true, spacing: 4, xs: 12, alignItems: "center", justifyContent: "center" },
                React.createElement(Grid, { xs: 4 },
                    React.createElement(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true }, "Search"))))),
        !showForm && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, xs: 12, alignItems: "center", justifyContent: "center" },
                React.createElement(Grid, { xs: 4 },
                    React.createElement(Box, { mb: 1 },
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: function () {
                                toggleShowForm(!showForm);
                            }, fullWidth: true }, "Show Form")))),
            React.createElement(SearchResults, { response_data: response_data })))));
}
