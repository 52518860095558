import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import "../../css/app.css";
import { isAmexCard, validateCreditCard, validateZip } from "../utils/utils";
import GenericControllerTextInput from "./GenericFormFields/TextInput";
import GenericNumberInput from "./GenericFormFields/GenericNumberInput";
var CreditCardForm = function (props) {
    var _a, _b;
    var member = props.member, errors = props.errors, control = props.control, cardNumber = props.cardNumber, _c = props.showContribution, showContribution = _c === void 0 ? true : _c, _d = props.showCVV, showCVV = _d === void 0 ? true : _d;
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Credit Card Information")),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericNumberInput, { defaultValue: cardNumber, type: "text", label: "Credit Card Number", name: "payment[card_num]", error: errors.payment
                        ? "card_num" in errors.payment
                        : false, rules: {
                        required: true,
                        validate: function (value) {
                            return validateCreditCard(value);
                        },
                    }, helperText: "Please provide 15/16 Card Number", placeholder: "#### #### #### ####", format: isAmexCard(cardNumber)
                        ? "#### ###### #####"
                        : "#### #### #### ####", mask: "#", control: control })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { defaultValue: (_a = member === null || member === void 0 ? void 0 : member.full_name) !== null && _a !== void 0 ? _a : "", type: "text", label: "Name on Credit Card", name: "payment[card_holder]", rules: { required: true }, control: control, error: errors.payment
                        ? "card_holder" in errors.payment
                        : false })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericNumberInput, { defaultValue: (_b = member === null || member === void 0 ? void 0 : member.res_zip) !== null && _b !== void 0 ? _b : "", type: "text", label: "Billing Zip", name: "payment[bill_zip]", error: errors.payment
                        ? "bill_zip" in errors.payment
                        : false, rules: {
                        required: true,
                        validate: function (value) { return validateZip(value); },
                    }, control: control, format: "#####", mask: "#", helperText: "Enter 5 digit Zip" })),
            React.createElement(Grid, { xs: 6, md: 3 },
                React.createElement(GenericNumberInput, { defaultValue: "", type: "text", label: "Exp Month", name: "payment[exp_month]", rules: {
                        required: true,
                        validate: function (value) {
                            return value.length === 2 &&
                                Number(value) >= 1 &&
                                Number(value) <= 12;
                        },
                    }, error: errors.payment
                        ? "exp_month" in errors.payment
                        : false, control: control, format: "##", placeholder: "MM", helperText: "Must be in MM format.", mask: "M" })),
            React.createElement(Grid, { xs: 6, md: 3 },
                React.createElement(GenericNumberInput, { defaultValue: "", type: "text", label: "Exp Year", name: "payment[exp_year]", rules: {
                        required: true,
                        validate: function (value) {
                            return value.length === 4 &&
                                Number(value) >= new Date().getFullYear();
                        },
                    }, error: errors.payment
                        ? "exp_year" in errors.payment
                        : false, control: control, format: "####", placeholder: "YYYY", helperText: "Year must be current year or greater.", mask: "Y" })),
            showContribution && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericNumberInput, { defaultValue: "", type: "text", label: "Contribution", name: "payment[contribution]", rules: {
                        required: false,
                    }, error: false, control: control, placeholder: "$", prefix: "$" }))),
            showCVV && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, name: "payment[CVV]", label: "CVV", error: errors.payment ? "CVV" in errors.payment : false, rules: {
                        required: true,
                        validate: function (value) {
                            return (value.length === 3 ||
                                value.length === 4) &&
                                Number(value) > 0 &&
                                Number(value) <= 9999;
                        },
                    }, defaultValue: "" }))))));
};
export default CreditCardForm;
